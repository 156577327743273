<div class="container mt-5 pt-4 bg-white rounded-top shadow">
  <div>
    <button type="button" class="btn btn-warning my-3 float-right" (click)="estadoCuenta()">
      Estado de Cuenta
    </button>
    <button type="button" class="btn btn-warning my-3 mr-1 float-right" (click)="antiguedadSaldos()">
      Antiguedad de Saldos
    </button>
    <hr> 
    <h3 class="text-center">Reporte de Pagos</h3>
  </div>
  
    <table class="table">
     
      <tbody>
        <tr>
            <th scope="row">Nombre</th>
          <td>
            <div class="col-md-12">
              <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="pacientesSettings"
    [data]="pacientes" [(ngModel)]="selectedPacienteItems">
  </ng-multiselect-dropdown>
              <!-- <input type="text" class="form-control valGlobal" id="paciente" name="paciente">                     -->
          </div>        </td>
          <th scope="row">Fecha</th>
          <td>
            <input type="date" class="form-control" id="fechaDesde" name="fechaDesde" value="yyyy-MM-dd"
            [(ngModel)]="pagoFiltro.fecha1">
          </td>
          <td>
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </td>
          <td>
            <input type="date" class="form-control" id="fechaHasta" name="fechaHasta" value="yyyy-MM-dd" 
            [(ngModel)]="pagoFiltro.fecha2">
          </td>
          <th scope="row">Estatus Pago</th>
        <td>
          <div class="input-group">
            <select class="form-control" name="estatusPago" #estatusPago="ngModel" [(ngModel)]="pagoFiltro.estatusPago" ngModel  
            (change)="onEstatusPago($event.target.value)">
              <option *ngFor="let estatusPago of estatusPagos" [value]="estatusPago.idTipoEstatusPago">
                {{estatusPago.Nombre}}
              </option>
            </select>
          </div>
        </td>
        </tr>
      </tbody>
    </table>
  
    <div class="row text-center pb-4">
      <div class="col">
        <button type="button" class="btn btn-primary" (click)="getPagos()">Buscar</button> &nbsp;&nbsp;
        <button type="button" class="btn btn-danger" (click)="resetFields()">Limpiar Filtro</button> &nbsp;&nbsp;
      </div>
    </div>
  </div>
  
  <div class="container bg-white rounded-bottom shadow pb-4">
    <div class="mat-elevation-z8">
      <table mat-table matTableExporter [dataSource]="pagoSource" class="mat-elevation-z8"
        #exporter="matTableExporter">
        <ng-container matColumnDef="idPago">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.idPagoServicio}} </td>
        </ng-container>
        
        <ng-container matColumnDef="idServicio">
            <th mat-header-cell *matHeaderCellDef> Id de Servicio </th>
            <td mat-cell *matCellDef="let element"> {{element.idServicio}} </td>
          </ng-container>
        <ng-container matColumnDef="montodePago">
          <th mat-header-cell *matHeaderCellDef> Monto del Pago </th>
          <td mat-cell *matCellDef="let element"> $ {{element.monto}} </td>
        </ng-container>
        <ng-container matColumnDef="fechaCreacion">
            <th mat-header-cell *matHeaderCellDef> Fecha de Pago </th>
            <td mat-cell *matCellDef="let element"> {{element.fechaPago}}  </td>
          </ng-container>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.nombrecompleto}} </td>
          </ng-container>
        <ng-container matColumnDef="motivoPago">
            <th mat-header-cell *matHeaderCellDef> Motivo de Pago </th>
            <td mat-cell *matCellDef="let element"> {{element.motivo}} </td>
          </ng-container>
        <ng-container matColumnDef="estatusPago">
        <th mat-header-cell *matHeaderCellDef> Estatus Pago</th>
        <td mat-cell *matCellDef="let element"> {{element.estatusPagoNombre}} </td>
      </ng-container>
        <tr mat-header-row *matHeaderRowDef="pagoColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: pagoColumns;"></tr>
      </table>
<!-- <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[1, 5, 25, 100]"
        (page)="pageEvent = getPagos($event)" aria-label="Selecciona los elementos a mostrar">
      </mat-paginator> 
    </div>-->
    
    <div class="row text-center mt-4">
        <div class="col">
          <button class="btn btn-success" (click)="exporter.exportTable('xlsx', {fileName:'reportePago'})"><fa-icon [icon]="faFileExcel"></fa-icon> Exportar como Excel</button>
        </div>
      </div>
  </div>

