export class PagoFiltro {
    
    public fecha1:Date;
    public fecha2:Date;
    public idServicio:number;
    public limit:number;
    public start:number;
    public estatusPago: number;
    public pacientes:any[];
    
    constructor() {
    }
}      