<div class="container w-75 bg-white mt-5 rounded shadow">
    <div class="row align-items-stretch">
        <div class="col bg d-none d-lg-block col-md-5 col-lg-5 col-xl-6 rounded-left">

        </div>
        <div class="col bg-white p-5 rounded">
            <div class="text-right">
                <img src="assets/images/logo.png" alt="" height="36">
            </div>
            <h2 class="fw-bold text-center py-5">Bienvenido</h2>

            <!-- LOGIN -->
            <form action="#">
                <div class="mb-4">
                    <label for="correo" class="form-label">Correo electrónico</label>
                    <input type="email" class="form-control" id="correo" name="correo" ngModel #correo="ngModel"
                    [(ngModel)]="login.correo">
                </div>
                <div class="mb-4">
                    <label for="password" class="form-label">Contraseña</label>
                    <input type="password" class="form-control" id="password" name="password" ngModel #password="ngModel"
                    [(ngModel)]="login.password">

                </div>

                <div class="d-grid">
                    <button type="submit" class="btn btn-primary btn-block"  (click)="enviar()">Iniciar Sesión</button>
                </div>
                <div class="my-3">
                    <span>¿No tienes cuenta? <a href="#">Regístrate</a></span> <br>
                    <span><a href="#">Recuperar Password</a></span>
                </div>
            </form>

        </div>
    </div>
</div>