<!-- <div style="padding: 10px;"> -->

<div class="container mt-5 pt-4 bg-white rounded-top shadow">
  <button type="button" class="btn btn-success mb-4" (click)="agregarColaborador()">
    <h3>
      <fa-icon [icon]="faUserNurse"></fa-icon>
    </h3> Agregar Nuevo Colaborador
  </button> &nbsp;&nbsp;
  <table class="table">
    <tbody>
      <tr>
        <th scope="row">Edad</th>
        <td>
          <input maxlength="2" size="2" type="text" class="form-control" id="edad" name="edadDesde" placeholder="Desde"
            [(ngModel)]="colaboradorFiltro.edad1">
        </td>
        <td>
          <input maxlength="2" size="2" type="text" class="form-control" id="edad" name="edadHasta" placeholder="Hasta"
            [(ngModel)]="colaboradorFiltro.edad2">
        </td>
        <th scope="row">Genero</th>
        <td>
          <div class="input-group">
            <select class="form-control" name="sexoFiltro" [(ngModel)]="colaboradorFiltro.genero">
              <option value="" selected disabled hidden>Seleccionar</option>
              <option *ngFor="let sexo of sexos" [value]="sexo.idSexo">
                {{sexo.nombre}}
              </option>
            </select>
            <button type="button" class="btn btn-secondary" (click)="resetFieldGenero()">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
        </td>
        <th scope="row">Habilidades</th>
        <td>
          <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="habilidadesSettings" [data]="habilidades"
            [(ngModel)]="habilidadesSelected" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </td>
      </tr>
      <tr>
        <th scope="row">Peso</th>
        <td>
          <input maxlength="3" size="3" type="text" class="form-control" id="peso" name="pesoDesde" placeholder="Desde"
            [(ngModel)]="colaboradorFiltro.peso1">
        </td>
        <td>
          <input maxlength="3" size="3" type="text" class="form-control" id="peso" name="pesoHasta" placeholder="Hasta"
            [(ngModel)]="colaboradorFiltro.peso2">
        </td>
        <th scope="row">Permanencia</th>
        <td>
          <div class="input-group">
            <select class="form-control" name="permanenciaFiltro" [(ngModel)]="colaboradorFiltro.permanencia">
              <option value="" selected disabled hidden>Seleccionar</option>
              <option *ngFor="let permanencia of permanencias" [value]="permanencia.idPermanencia">
                {{permanencia.nombre}}
              </option>
            </select>
            <button type="button" class="btn btn-secondary" (click)="resetFieldPermanencia()">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
        </td>
        <th scope="row">Dias laborales</th>
        <td>
          <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="diasLaboralesSettings"
            [data]="diasLaborales" [(ngModel)]="diasLaboralesSelected" (onSelect)="onItemSelect($event)"
            (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </td>
      </tr>
      <tr>
        <th scope="row">Estatura</th>
        <td>
          <input maxlength="3" size="3" type="text" class="form-control" id="estatura" name="estaturaDesde"
            placeholder="Desde" [(ngModel)]="colaboradorFiltro.estatura1">
        </td>
        <td>
          <input maxlength="3" size="3" type="text" class="form-control" id="estatura" name="estaturaHasta"
            placeholder="Hasta" [(ngModel)]="colaboradorFiltro.estatura2">
        </td>
        <th scope="row">Zona Laboral</th>
        <td>
          <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="zonasSettings" [data]="zonasLaborales"
            [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </td>
        <th scope="row">Turno Laboral</th>
        <td>
          <div class="input-group">
            <select class="form-control" name="turnoHorarioFiltro" [(ngModel)]="colaboradorFiltro.turnoHorario">
              <option value="" selected disabled hidden>Seleccionar</option>
              <option value="Dia">Dia</option>
              <option value="Noche">Noche</option>
              <option value="TodoElDia">Todo el Dia</option>
            </select>
            <button type="button" class="btn btn-secondary" (click)="resetFieldTurnoLaboral()">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">Calificación</th>
        <td>
          <input maxlength="1" size="1" type="text" class="form-control" id="idCalificacion"
            [(ngModel)]="colaboradorFiltro.calificacion1" name="calificacionDesde" placeholder="Desde">
        </td>
        <td>
          <input maxlength="1" size="1" type="text" class="form-control" id="idCalificacion"
            [(ngModel)]="colaboradorFiltro.calificacion2" name="calificacionHasta" placeholder="Hasta">
        </td>
        <th scope="row">Tipo Colaborador</th>
        <td>
          <div class="input-group">
            <select class="form-control" name="tipoColaboradorFiltro" [(ngModel)]="colaboradorFiltro.tipoColaborador">
              <option value="" selected disabled hidden>Seleccionar</option>
              <option *ngFor="let tipoColaborador of tiposColaboradores" [value]="tipoColaborador.idTipoColaborador">
                {{tipoColaborador.nombre}}
              </option>
            </select>
            <button type="button" class="btn btn-secondary" (click)="resetFieldTipoColaborador()">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
        </td>
        <th scope="row">Nombre</th>
        <td>
          <input maxlength="30" type="text" class="form-control" id="nombre" name="nombre" placeholder="Nombre"
            [(ngModel)]="colaboradorFiltro.nombre">
        </td>
        <!-- <td colspan="1">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="covidFiltro"
              [(ngModel)]="colaboradorFiltro.atiendeCovid">
            <label class="form-check-label" for="covidFiltro">Atiende COVID</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="autoFiltro" [(ngModel)]="colaboradorFiltro.autoPropio">
            <label class="form-check-label" for="autoFiltro">Auto Propio</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="viajeFiltro"
              [(ngModel)]="colaboradorFiltro.dispuestoViajar">
            <label class="form-check-label" for="viajeFiltro">Disp. para viajar</label>
          </div>
        </td>
        <td>
          <button type="button" class="btn btn-danger" (click)="resetFields()">Limpiar Filtro</button>
          <br>
          <button type="button" class="btn btn-primary mt-1" (click)="mostrarFiltros()">{{filtrosOcultos ? 'Ocultar' :
            'Mostrar' }} Filtros</button>
        </td> -->
      </tr>
      <tr>
        <td colspan="1">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="covidFiltro"
              [(ngModel)]="colaboradorFiltro.atiendeCovid">
            <label class="form-check-label" for="covidFiltro">Atiende COVID</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="autoFiltro" [(ngModel)]="colaboradorFiltro.autoPropio">
            <label class="form-check-label" for="autoFiltro">Auto Propio</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="viajeFiltro"
              [(ngModel)]="colaboradorFiltro.dispuestoViajar">
            <label class="form-check-label" for="viajeFiltro">Disp. para viajar</label>
          </div>
        </td>
        <td>
          <button type="button" class="btn btn-danger" (click)="resetFields()">Limpiar Filtro</button>
          <button type="button" class="btn btn-primary mt-1" (click)="mostrarFiltros()">{{filtrosOcultos ? 'Ocultar' :
            'Mostrar' }} Filtros</button>
        </td>
      </tr>
    </tbody>
  </table>

  <table class="table" *ngIf="filtrosOcultos">
    <tbody>
      <tr>
        <td>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="hijosFiltro" [(ngModel)]="colaboradorFiltro.hijos">
            <label class="form-check-label" for="hijosFiltro">Tiene hijos</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="hijosVivenFiltro"
              [(ngModel)]="colaboradorFiltro.hijosViven">
            <label class="form-check-label" for="hijosVivenFiltro">Viven con colaborador</label>
          </div>
        </td>
        <td>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="hacerComerFiltro"
              [(ngModel)]="colaboradorFiltro.hacerComer">
            <label class="form-check-label" for="hacerComerFiltro">Hace de comer</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="limpiarUtensiliosCocinaFiltro"
              [(ngModel)]="colaboradorFiltro.limpiarUtensiliosCocina">
            <label class="form-check-label" for="limpiarUtensiliosCocinaFiltro">Limpia utensilios</label>
          </div>
        </td>
        <td>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="limpiarDormitorioFiltro"
              [(ngModel)]="colaboradorFiltro.limpiarDormitorio">
            <label class="form-check-label" for="limpiarDormitorioFiltro">Limpia dormitorio</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="limpiarBanoFiltro"
              [(ngModel)]="colaboradorFiltro.limpiarBano">
            <label class="form-check-label" for="limpiarBanoFiltro">Limpia baño</label>
          </div>
        </td>
        <td>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="ayudaPacienteFiltro"
              [(ngModel)]="colaboradorFiltro.ayudaPaciente">
            <label class="form-check-label" for="ayudaPacienteFiltro">Apoya en baño, regadera, cama y cambio de
              pañal</label>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


  <div class="row text-center pb-4">
    <div class="col">
      <button type="button" class="btn btn-primary" (click)="getColaboradores()">Buscar</button> &nbsp;&nbsp;
      <button type="button" class="btn btn-warning" *ngIf="colaborador != null">Editar</button>&nbsp;&nbsp;
      <button type="button" class="btn btn-danger" *ngIf="colaborador">Eliminar</button>
    </div>
  </div>
</div>

<div class="container bg-white rounded-bottom shadow pb-4">
  <div class="mat-elevation-z8">
    <table mat-table matTableExporter [dataSource]="colaboradorSource" class="mat-elevation-z8"
      #exporter="matTableExporter">
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
      </ng-container>
      <ng-container matColumnDef="a_paterno">
        <th mat-header-cell *matHeaderCellDef> Apellido Paterno </th>
        <td mat-cell *matCellDef="let element"> {{element.a_paterno}} </td>
      </ng-container>
      <ng-container matColumnDef="a_materno">
        <th mat-header-cell *matHeaderCellDef> Apellido Materno </th>
        <td mat-cell *matCellDef="let element"> {{element.a_materno}} </td>
      </ng-container>
      <ng-container matColumnDef="telefono">
        <th mat-header-cell *matHeaderCellDef> Telefono Princ. </th>
        <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
      </ng-container>
      <ng-container matColumnDef="correoElectronico">
        <th mat-header-cell *matHeaderCellDef> Correo</th>
        <td mat-cell *matCellDef="let element"> {{element.correoElectronico}} </td>
      </ng-container>
      <ng-container matColumnDef="idCalificacion">
        <th mat-header-cell *matHeaderCellDef> Calificación</th>
        <td mat-cell *matCellDef="let element">
          <ngb-rating [max]="5" [(rate)]="element.idCalificacion" [readonly]="true"></ngb-rating>
        </td>
      </ng-container>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> Acciones </th>
        <td mat-cell *matCellDef="let element">
          <button class="btn btn-primary mr-2" (click)="openDialog(element.idColaborador)">
            <mat-icon>search</mat-icon>
          </button>
          <button class="btn btn-primary" (click)="modificacionColaborador(element.idColaborador)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="Seleccionar">
        <th mat-header-cell *matHeaderCellDef> Seleccionar</th>
        <td mat-cell *matCellDef="let element"><input type="checkbox" (change)="whatsappSelect(element)" /></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="colaboradorColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: colaboradorColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[1, 5, 25, 100]"
      (page)="pageEvent = getColaboradores($event)" aria-label="Selecciona los elementos a mostrar">
    </mat-paginator>
  </div>
  <br>
  <div class="row text-center pb-4">
    <div class="col">
      <button class="btn btn-success" (click)="exporter.exportTable('csv')">Exportar como Excel</button>
      <button class="btn btn-success ml-5" style="background-color: #25D366;" (click)="colaboradoresWhatsapp()">
        <fa-icon [icon]="faWhatsapp"></fa-icon> Mandar mensajes por WhatsApp
      </button>
    </div>
  </div>

</div>