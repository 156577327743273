export class Experiencia {
    public idExperiencia:number;
    public empresa:string;
    public fechaInicio:any;
    public fechaFin:any;
    public referencia:string;
    public comentario:string;
    public telefono:string;
    
    constructor() {
    }
}