<!-- <form>
    <div class="form-group">
        <label for="whatsapp">Mensaje</label>
        <textarea class="form-control" id="whatsapp" rows="5"></textarea>
    </div>
    <button type="submit" class="btn btn-success">Enviar</button>
    <button type="button" class="btn btn-danger ml-3">Cerrar</button>
</form> -->
<form style="background: #00D8D6; background: linear-gradient(to right, #00D8D6, #6EC1E4);" #myForm="ngForm">
    <ngb-toast-container></ngb-toast-container>
    <mat-tab-group mat-align-tabs="center" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
        <mat-tab label="Mensaje de WhatsApp">
            <div class="container mt-5 pt-4 bg-white rounded-top shadow">
                <h3 class="text-center">Mensaje de Whatsapp para Colaboradores</h3>
                <hr class="py-1" />
                <div class="row mt-4 pb-4 mx-1">
                    <label for="whatsapp">Mensaje</label>
                    <textarea class="form-control" id="whatsapp" name="whatsapp" rows="10" maxlength="1600" ngModel #whatsapp="ngModel"
                    [(ngModel)]="colaboradorWhatsapp.mensaje"></textarea>
                </div>
                <div class="row mx-1">
                    <h5>Añadir Destinatarios</h5>
                </div>
                <div class="row pb-2 ml-4 mr-1">
                      <div class="form-group row col-md-7">
                        <label for="inputPassword" class="col-md-8 col-form-label">Capturar un numero de celular con WhatsApp:</label>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input maxlength="10" size="10" type="tel" class="form-control" id="telefono1" name="telefono1" placeholder="10 Dígitos" pattern="[0-9]*"
                                minlength="10" ngModel #telefono1="ngModel" [(ngModel)]="colaboradorNuevoWhatsapp.telefono">
                                <button type="button" class="btn btn-success" (click)="addWhatsappColaboradores()">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </div>
                        </div>
                      </div>
                </div>
                <div class="row mx-1">
                    <h5>Este mensaje será enviado a:</h5>
                </div>
                <div class="row pb-2 ml-4 mr-1">
                    <div class="col-md-6">
                        <div class="container">
                            <div class="mat-elevation-z8">
                                <table mat-table matTableExporter [dataSource]="colaboradorWhatsappSource" class="mat-elevation-z8"
                                #exporter="matTableExporter">
                                <ng-container matColumnDef="nombre">
                                    <th mat-header-cell *matHeaderCellDef> Nombre </th>
                                    <td mat-cell *matCellDef="let element"> {{element.nombrecompleto}} </td>
                                </ng-container>
                                <ng-container matColumnDef="telefono">
                                    <th mat-header-cell *matHeaderCellDef> Telefono </th>
                                    <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="colaboradorWhatsappColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: colaboradorWhatsappColumns;"></tr>
                                </table>
                                <mat-paginator #paginator1="matPaginator" [pageSizeOptions]="[5, 10, 20]"
                                    showFirstLastButtons 
                                    aria-label="Selecciona los elementos a mostrar">
                                </mat-paginator>
                            </div>
                        </div>
                </div>
                <div class="col-md-6">
                    <div class="container">
                        <div class="mat-elevation-z8">
                            <table mat-table matTableExporter [dataSource]="colaboradorNuevoWhatsappSource" class="mat-elevation-z8"
                            #exporter="matTableExporter">
                            <ng-container matColumnDef="destinatarioAdicional">
                                <th mat-header-cell *matHeaderCellDef> Destinatario Adicional </th>
                                <td mat-cell *matCellDef="let element"> Destinatario Adicional #{{element.destinatarioAdicional}} </td>
                            </ng-container>
                            <ng-container matColumnDef="telefono">
                                <th mat-header-cell *matHeaderCellDef> Telefono </th>
                                <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
                            </ng-container>
                            <ng-container matColumnDef="acciones">
                                <th mat-header-cell *matHeaderCellDef> Acciones </th>
                                <td mat-cell *matCellDef="let element">
                                  <button class="btn btn-danger" (click)="borraNumero(element)">
                                    <fa-icon [icon]="faTrashAlt"></fa-icon>
                                  </button>
                                </td>
                              </ng-container>
                            <tr mat-header-row *matHeaderRowDef="colaboradorNuevoWhatsappColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: colaboradorNuevoWhatsappColumns;"></tr>
                            </table>
                            <!-- <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[1, 5, 25, 100]"
                            (page)="pageEvent = getWhatsappColaboradores($event)" aria-label="Selecciona los elementos a mostrar">
                            </mat-paginator> -->
                            <mat-paginator #paginator2="matPaginator" [pageSizeOptions]="[5, 10, 20]"
                                    showFirstLastButtons 
                                    aria-label="Selecciona los elementos a mostrar">
                                </mat-paginator>
                        </div>
                    </div>
            </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="container bg-white rounded-bottom shadow">
      <div class="row text-center pb-4">
        <div class="col">
            <button type="submit" class="btn btn-success" (click)="enviarWhatsapp(myForm)">Enviar</button>
            <button type="button" class="btn btn-danger ml-3" (click)="cancelar()">Cancelar</button>
        </div>
      </div>
    </div>
</form>