<form style="background: #00D8D6; background: linear-gradient(to right, #00D8D6, #6EC1E4);" #myForm="ngForm">
    <ngb-toast-container></ngb-toast-container>
    <mat-tab-group mat-align-tabs="center" [selectedIndex]="selected.value"
        (selectedIndexChange)="selected.setValue($event)">
        <mat-tab label="General">
            <div class="container mt-5 bg-white rounded-top shadow">
                <h4 class="pt-4 pb-1">Información General</h4>
                <div class="row">
                    <div class="col">
                        <label for="nombre" class="form-label">Nombre(s)<span class="text-danger"> *</span></label>
                        <input type="text" class="form-control valGlobal" id="nombre" name="nombre" ngModel
                            #nombre="ngModel" [(ngModel)]="cliente.nombre" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+">
                        <!-- <input type="text" class="form-control valGlobal" id="nombre" name="nombre" ngModel #nombre="ngModel"
                [(ngModel)]="cliente.nombre" pattern="[a-zA-Z ]*" required> -->


                    </div>

                    <div class="col">
                        <label for="paterno" class="form-label">Apellido Paterno<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control valGlobal" id="apaterno" name="apaterno"
                            #apaterno="ngModel" [(ngModel)]="cliente.a_paterno" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+">
                        <!-- <input type="text" class="form-control valGlobal" id="apaterno" name="apaterno" ngModel #apaterno="ngModel"
                [(ngModel)]="cliente.a_paterno" pattern="[a-zA-Z ]*" required> -->

                    </div>

                    <div class="col">
                        <label for="materno" class="form-label">Apellido Materno<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control" id="amaterno" name="amaterno" ngModel
                            #amaterno="ngModel" [(ngModel)]="cliente.a_materno" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+">
                        <!-- <input type="text" class="form-control" id="amaterno" name="amaterno" ngModel #amaterno="ngModel"
                [(ngModel)]="cliente.a_materno" pattern="[a-zA-Z ]*" required> -->

                    </div>
                </div>


                <hr class="py-1" />
                <h4 class="pb-1">Lugar y Fecha de Nacimiento</h4>
                <div class="row mt-4 pb-4">
                    <div class="col">
                        <label for="nacimiento" class="form-label">Fecha de Nacimiento<span class="text-danger">
                                *</span></label>
                        <input type="date" class="form-control" id="fechanacimiento" name="fechanacimiento" ngModel
                            #fechanacimiento="ngModel" [(ngModel)]="cliente.fecha_nacimiento" value="yyyy-MM-dd">
                        <!-- <input type="date" class="form-control" id="fechanacimiento" name="fechanacimiento" value="yyyy-MM-dd" ngModel
                #fechanacimiento="ngModel" [(ngModel)]="cliente.fecha_nacimiento" required> -->


                    </div>
                    <div class="col">
                        <label for="paisNac" class="form-label">País<span class="text-danger"> *</span></label>
                        <select class="form-control" name="paisNac" ngModel #paisNac="ngModel"
                            [(ngModel)]="cliente.idPaisNacimiento">
                            <!-- <select class="form-control" name="paisNac" ngModel
              #paisNac="ngModel" [(ngModel)]="cliente.idPaisNacimiento" required> -->
                            <option value="" selected disabled hidden>Seleccionar</option>
                            <option *ngFor="let pais of paises" [value]="pais.idPais">
                                {{pais.nombre}}
                            </option>
                        </select>

                    </div>
                    <div class="col">
                        <label for="estadoNac" class="form-label">Estado<span class="text-danger"> *</span></label>
                        <select class="form-control" name="estadoNac" id="estadoNac" ngModel #estadoNac="ngModel"
                            [(ngModel)]="cliente.idEstadoNacimiento" (change)="comboCiudades($event.target.value)">
                            <!-- <select class="form-control" name="estadoNac" id="estadoNac" ngModel #estadoNac="ngModel" 
              [(ngModel)]="cliente.idEstadoNacimiento"
                (change)="comboCiudades($event.target.value)" required> -->
                            <option value="" selected disabled hidden>Seleccionar</option>
                            <option *ngFor="let estado of estados" [value]="estado.idEstado">
                                {{estado.nombre}}
                            </option>
                        </select>

                    </div>
                    <div class="col">
                        <label for="ciudadNac" class="form-label">Ciudad<span class="text-danger"> *</span></label>
                        <select class="form-control" name="ciudadNac" (change)="onCiudadNacimiento($event.target.value)"
                            #ciudadNac="ngModel" ngModel [(ngModel)]="cliente.idCiudadNacimiento">
                            <!-- <select class="form-control" name="ciudadNac" (change)="onCiudadNacimiento($event.target.value)" #ciudadNac="ngModel"  ngModel required> -->
                            <option value="" selected disabled hidden>Seleccionar</option>
                            <option *ngFor="let ciudad of ciudades" [value]="ciudad.idCiudad">
                                {{ciudad.nombre}}
                            </option>
                        </select>

                    </div>

                </div>
                <div class="row pb-4">
                    <div class="col-md-12"><span class="font-italic">Los campos con </span><span
                            class="text-danger font-italic">*</span><span class="font-italic"> son obligatorios</span>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Dirección">
            <div class="container mt-5 bg-white rounded-top shadow">
                <h4 class="pt-4 pb-1">Dirección</h4>
                <div class="row">
                    <div class="col">
                        <label for="calle" class="form-label">Calle<span class="text-danger"> *</span></label>
                        <input type="text" class="form-control" id="calle1" name="calle1" ngModel #calle1="ngModel"
                            [(ngModel)]="cliente.calle1">
                        <!-- <input type="text" class="form-control" id="calle1" name="calle1" ngModel #calle1="ngModel" [(ngModel)]="cliente.calle1" required> -->

                    </div>

                    <div class="col">
                        <label for="calle2" class="form-label">Calle 2</label>
                        <input type="text" class="form-control" id="calle1" name="calle2" ngModel #calle2="ngModel"
                            [(ngModel)]="cliente.calle2">
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-2">
                        <label for="numExterior" class="form-label">Número Exterior<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control" name="numExterior" ngModel #numExterior="ngModel"
                            [(ngModel)]="cliente.noExt">
                    </div>

                    <div class="col-md-2">
                        <label for="numInterior" class="form-label">Número Interior</label>
                        <input type="text" class="form-control" name="numInterior" ngModel #numInterior="ngModel"
                            [(ngModel)]="cliente.noInt">
                    </div>

                    <div class="col-md-2">
                        <label for="codigoPostal" class="form-label">Código Postal<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control" maxlength="5" name="codigoPostal"
                            [ngModel]="selectedCodigoPostal" (change)="onCodigoPostal($event.target.value)" ngModel
                            #codigoPostal="ngModel" pattern="[0-9]*">

                    </div>
                    <div class="col">
                        <label for="colonia" class="form-label">Colonia<span class="text-danger"> *</span></label>
                        <select class="form-control" name="colonia" ngModel #colonia="ngModel"
                            [(ngModel)]="cliente.idColonia" (change)="onColonia($event.target.value)">
                            <option *ngFor="let colonia of colonias" [value]="colonia.idColonia">
                                {{colonia.nombre}}
                            </option>
                        </select>
                    </div>
                </div>


                <!-- <div class="col">
                    <label for="colonia" class="form-label">Colonia<span class="text-danger"> *</span></label>
                    <select class="form-control" name="colonia" ngModel #colonia="ngModel"
                    (change)="onColonia($event.target.value)">
                        <option *ngFor="let colonia of colonias" [value]="colonia.idColonia">
                            {{colonia.nombre}}
                        </option>
                    </select>
                </div> -->

                <div class="row mt-4">
                    <div class="col">
                        <label for="ciudad" class="form-label">Ciudad<span class="text-danger"> *</span></label>
                        <select class="form-control" name="ciudad" (change)="onCiudad($event.target.value)">
                            <option *ngFor="let ciudad of ciudadesDir" [value]="ciudad.idCiudad">
                                {{ciudad.nombre}}
                            </option>
                        </select>
                    </div>

                    <div class="col">
                        <label for="estado" class="form-label">Estado<span class="text-danger"> *</span></label>
                        <select class="form-control" name="estado" (change)="onEstado($event.target.value)">
                            <option *ngFor="let estado of estadosDir" [value]="estado.idEstado">
                                {{estado.nombre}}
                            </option>
                        </select>
                    </div>

                    <div class="col">
                        <label for="pais" class="form-label">País<span class="text-danger"> *</span></label>
                        <select class="form-control" name="pais">
                            <option *ngFor="let pais of paises" [value]="pais.idPais">
                                {{pais.nombre}}
                            </option>
                        </select>
                    </div>

                </div>

                <div class="row mt-4 pb-4">
                    <div class="col">
                        <label for="referencia" class="form-label">Referencia</label>
                        <textarea name="referencia" class="form-control" ngModel #referencia="ngModel"
                            [(ngModel)]="cliente.referencia" rows="3" maxlength="65535"></textarea>
                    </div>

                </div>
                <div class="row pb-4">
                    <div class="col-md-12"><span class="font-italic">Los campos con </span><span
                            class="text-danger font-italic">*</span><span class="font-italic"> son obligatorios</span>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Información Personal">
            <div class="container mt-5 bg-white rounded-top shadow">
                <h4 class="pt-4 pb-1">Información Personal</h4>
                <div class="row">
                    <div class="col-md-4">
                        <label for="sexo" class="form-label">Genero<span class="text-danger"> *</span></label>
                        <select class="form-control" name="sexo" ngModel (change)="onSexo($event.target.value)"
                            #sexo="ngModel" [ngModel]="cliente.idSexo">
                            <!-- <select class="form-control" name="sexo" ngModel (change)="onSexo($event.target.value)"
              #sexo="ngModel" [ngModel]="cliente.idSexo" required > -->
                            <option value="" selected disabled hidden>Seleccionar</option>
                            <option *ngFor="let sexo of sexos" [value]="sexo.idSexo">
                                {{sexo.nombre}}
                            </option>

                        </select>

                    </div>

                    <div class="col-md-1">
                        <label for="peso" class="form-label">Peso (Kg)</label>
                        <input maxlength="3" size="3" type="text" class="form-control" name="peso" ngModel
                            #peso="ngModel" [(ngModel)]="cliente.peso" pattern="[0-9]*">

                    </div>

                    <div class="col-md-1">
                        <label for="estatura" class="form-label">Estatura(m)</label>
                        <input maxlength="4" size="4" type="text" class="form-control" name="estatura" ngModel
                            #estatura="ngModel" [(ngModel)]="cliente.estatura">
                    </div>

                    <div class="col-md-2">
                        <label for="complexion" class="form-label">Complexion</label>
                        <select class="form-control" name="complexion" ngModel #complexion="ngModel"
                            [ngModel]="cliente.idComplexion" (change)="onComplexion($event.target.value)">
                            <option value="" selected disabled hidden>Seleccionar</option>
                            <option *ngFor="let complexion of complexiones" [value]="complexion.idComplexion">
                                {{complexion.nombre}}
                            </option>

                        </select>
                    </div>
                    <div class="col-md-2">
                        <label for="estadocivil" class="form-label">Estado Civil<span class="text-danger">
                                *</span></label>
                        <select class="form-control" name="estadoCivil" ngModel #estadoCivil="ngModel"
                            [ngModel]="cliente.idEstadoCivil" (change)="onEstadoCivil($event.target.value)">
                            <!-- <select class="form-control" name="estadoCivil" ngModel
              #estadoCivil="ngModel" [ngModel]="cliente.idEstadoCivil" (change)="onEstadoCivil($event.target.value)" required> -->
                            <option value="" selected disabled hidden>Seleccionar</option>
                            <option *ngFor="let estadoCivil of estadosCiviles" [value]="estadoCivil.idEstadoCivil">
                                {{estadoCivil.nombre}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2">
                        <label for="imss" class="form-label">IMSS</label>
                        <input maxlength="11" size="11" type="text" class="form-control" name="imss" ngModel
                            #imss="ngModel" [(ngModel)]="cliente.imss" pattern="[0-9]*">
                    </div>
                </div>

                <div class="row mt-4 pb-4">
                    <div class="col-md-2">
                        <label for="telefono" class="form-label">Teléfono 1<span class="text-danger"> *</span></label>
                        <input maxlength="12" size="12" type="tel" (change)="onTelefono1($event.target.value)" class="form-control" name="telefono" ngModel
                            #telefono="ngModel" [(ngModel)]="cliente.telefono" placeholder="12 Dígitos"
                            pattern="[0-9]*" minlength="10">
                        <!-- <input maxlength="10" size="10" type="tel" class="form-control" name="telefono1" ngModel #telefono1="ngModel" [(ngModel)]="cliente.telefono" placeholder="10 Dígitos" pattern="[0-9]*" minlength="10" required> -->
                    </div>

                    <div class="col-md-2">
                        <label for="tipoTel1" class="form-label">Tipo de Teléfono 1</label>
                        <select class="form-control" name="tipoTel1" ngModel #tipoTel1="ngModel"
                            [ngModel]="cliente.idTipoTelefono" (change)="onTipoTel1($event.target.value)">
                            <option value="" selected disabled hidden>Seleccionar</option>
                            <option *ngFor="let tipoTelefono of tiposTelefono" [value]="tipoTelefono.idTipoTel">
                                {{tipoTelefono.nombre}}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2">
                        <label for="telefono2" class="form-label">Teléfono 2</label>
                        <input maxlength="12" size="12" type="tel" class="form-control" name="telefono2"
                            placeholder="12 Dígitos" ngModel #telefono2="ngModel" [(ngModel)]="cliente.telefono2"
                            pattern="[0-9]*" minlength="10">
                    </div>

                    <div class="col-md-2">
                        <label for="tipoTel2" class="form-label">Tipo de Teléfono 2</label>
                        <select class="form-control" name="tipoTel2" ngModel #tipoTel2="ngModel"
                            [ngModel]="cliente.idTipoTelefono2" (change)="onTipoTel2($event.target.value)">
                            <option value="" selected disabled hidden>Seleccionar</option>
                            <option *ngFor="let tipoTelefono of tiposTelefono" [value]="tipoTelefono.idTipoTel">
                                {{tipoTelefono.nombre}}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-4">
                        <label for="correoelectronico" class="form-label">Correo Electrónico<span class="text-danger">
                                *</span></label>
                        <input type="email" class="form-control" name="correoelectronico" ngModel
                            #correoelectronico="ngModel" [(ngModel)]="cliente.correoElectronico"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                        <!-- <input type="email" class="form-control" name="correoelectronico" ngModel #correoelectronico="ngModel" [(ngModel)]="cliente.correoElectronico" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required> -->
                    </div>
                </div>


                <div class="row mt-4 pb-4">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label">Seguro de Gastos Medicos Mayores<span class="text-danger">
                                    *</span></label>
                            <div class="form-control">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="sgmm" id="sgmm" value="option1"
                                        (click)="enableSeguros()">
                                    <label class="form-check-label" for="inlineRadio1">Sí</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="sgmm" id="inlineRadio2"
                                        value="option2" (click)="disableSeguros()">
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col">
                        <label for="aseguradora" class="form-label">Aseguradora</label>
                        <input type="text" class="form-control" name="aseguradora" ngModel #aseguradora="ngModel"
                            [(ngModel)]="cliente.sgmm" [disabled]="textBoxDisabledSeg" placeholder="Nombre">
                    </div>
                </div>
                <hr class="py-1" />
                <h4 class="pb-1">Contactos</h4>
                <div class="row mt-4 pb-4">
                    <div class="col">
                        <label for="nombreContacto" class="form-label">Nombre<span class="text-danger"> *</span></label>
                        <input type="text" class="form-control valGlobal" id="nombreContacto" name="nombreContacto"
                            ngModel #nombreContacto="ngModel" [(ngModel)]="cliente.nombreContacto" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+">
                        <!-- <input type="date" class="form-control" id="fechanacimiento" name="fechanacimiento" value="yyyy-MM-dd" ngModel
                #fechanacimiento="ngModel" [(ngModel)]="cliente.fecha_nacimiento" required> -->


                    </div>
                    <div class="col">
                        <label for="idParentescoContacto" class="form-label">Parentesco<span class="text-danger">
                                *</span></label>
                        <select class="form-control" name="idParentescoContacto" ngModel #idParentescoContacto="ngModel"
                            [ngModel]="cliente.idParentescoContacto" (change)="onParentesco1($event.target.value)">
                            <!-- <select class="form-control" name="paisNac" ngModel
              #paisNac="ngModel" [(ngModel)]="cliente.idPaisNacimiento" required> -->

                            <option *ngFor="let parentesco of parentescos" [value]="parentesco.idParentesco">
                                {{parentesco.nombre}}
                            </option>
                        </select>

                    </div>
                    <div class="col">
                        <label for="telefonoContacto" class="form-label">Telefono<span class="text-danger">
                                *</span></label>
                        <input maxlength="12" size="12" type="tel" class="form-control" name="telefonoContacto"
                            placeholder="12 Dígitos" ngModel #telefonoContacto="ngModel"
                            [(ngModel)]="cliente.telefonoContacto" pattern="[0-9]*" minlength="10">
                    </div>
                    <div class="col">
                        <label for="correoContacto" class="form-label">Correo Electrónico<span class="text-danger">
                                *</span></label>
                        <input type="email" class="form-control" name="correoContacto" ngModel #correoContacto="ngModel"
                            [(ngModel)]="cliente.correoContacto" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">

                    </div>

                </div>
                <div class="row mt-4 pb-4">
                    <div class="col">
                        <label for="nombreContacto2" class="form-label">Nombre<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control valGlobal" id="nombreContacto2" name="nombreContacto2"
                            ngModel #nombreContacto2="ngModel" [(ngModel)]="cliente.nombreContacto2"
                            pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+">
                        <!-- <input type="date" class="form-control" id="fechanacimiento" name="fechanacimiento" value="yyyy-MM-dd" ngModel
                #fechanacimiento="ngModel" [(ngModel)]="cliente.fecha_nacimiento" required> -->


                    </div>
                    <div class="col">
                        <label for="idParentescoContacto2" class="form-label">Parentesco<span class="text-danger">
                                *</span></label>
                        <select class="form-control" name="idParentescoContacto2" #idParentescoContacto2="ngModel"
                            [ngModel]="cliente.idParentescoContacto2" (change)="onParentesco2($event.target.value)">
                            <!-- <select class="form-control" name="paisNac" ngModel
              #paisNac="ngModel" [(ngModel)]="cliente.idPaisNacimiento" required> -->
                            <option value="" selected disabled hidden>Seleccionar</option>
                            <option *ngFor="let parentesco of parentescos" [value]="parentesco.idParentesco">
                                {{parentesco.nombre}}
                            </option>
                        </select>

                    </div>
                    <div class="col">
                        <label for="telefonoContacto2" class="form-label">Telefono<span class="text-danger">
                                *</span></label>
                        <input maxlength="12" size="12" type="tel" class="form-control" name="telefonoContacto2"
                            placeholder="12 Dígitos"  ngModel #telefonoContacto2="ngModel"
                            [(ngModel)]="cliente.telefonoContacto2" pattern="[0-9]*" minlength="10">
                    </div>
                    <div class="col">
                        <label for="correoContacto2" class="form-label">Correo Electrónico<span class="text-danger">
                                *</span></label>
                        <input type="email" class="form-control" name="correoContacto2" ngModel
                            #correoContacto2="ngModel" [(ngModel)]="cliente.correoContacto2"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">

                    </div>

                </div>
                <div class="row pb-4">
                    <div class="col-md-12"><span class="font-italic">Los campos con </span><span
                            class="text-danger font-italic">*</span><span class="font-italic"> son obligatorios</span>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Medico">
            <div class="container mt-5 bg-white rounded-top shadow">
                <h4 class="pt-4 pb-1">Medico</h4>
                <h6 class="pb-1">Favor de capturar los datos del Medico encargado del paciente
                </h6>
                <div class="row mt-4 pb-4">
                    <div class="col">
                        <label for="nombreMedico" class="form-label">Nombre completo<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control valGlobal" id="nombreMedico" name="nombreMedico" ngModel
                            #nombreMedico="ngModel" [(ngModel)]="cliente.nombreMedico" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+">
                        <!-- <input type="date" class="form-control" id="fechanacimiento" name="fechanacimiento" value="yyyy-MM-dd" ngModel
                #fechanacimiento="ngModel" [(ngModel)]="cliente.fecha_nacimiento" required> -->


                    </div>
                    <div class="col">
                        <label for="especialidadesMedico" class="form-label">Especialidad<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control valGlobal" id="especialidadesMedico"
                            name="especialidadMedico" ngModel #especialidadesMedico="ngModel"
                            [(ngModel)]="cliente.especialidadesMedico" pattern="[a-zA-Z ]*">


                    </div>
                    <div class="col">
                        <label for="telefonoMedico" class="form-label">Telefono<span class="text-danger">
                                *</span></label>
                        <input maxlength="10" size="10" type="tel" class="form-control" name="telefonoMedico" ngModel
                            #telefonoMedico="ngModel" [(ngModel)]="cliente.telefonoMedico" placeholder="10 Dígitos"
                            pattern="[0-9]*" minlength="10">
                    </div>
                    <div class="col">
                        <label for="correoMedico" class="form-label">Correo Electrónico<span class="text-danger">
                                *</span></label>
                        <input type="email" class="form-control" name="correoMedico" ngModel #correoMedico="ngModel"
                            [(ngModel)]="cliente.correoMedico" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">

                    </div>

                </div>



                <div class="row pb-4">
                    <div class="col-md-12"><span class="font-italic">Los campos con </span><span
                            class="text-danger font-italic">*</span><span class="font-italic"> son obligatorios</span>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Enfermedades">
            <div class="container mt-5 bg-white rounded-top shadow">
                <h4 class="pt-4 pb-1">Enfermedades</h4>
                <h6 class="pb-1">Favor de capturar cada uno de las enfermedades del paciente en los campos adecuados
                </h6>
                <div class="form-group dynamic-element">
                    <div class="row">
                       <!-- <div class="col-md-6">
                            <label for="enfermedadesActuales" class="form-label">Enfermedades Actuales<span
                                    class="text-danger">
                                    *</span></label>
                            <textarea name="enfermedadesActuales" class="form-control" ngModel
                                #enfermedadesActuales="ngModel" [(ngModel)]="cliente.enfermedadesActuales"
                                rows="5"></textarea>
                            <div class="form-group">
                                <label class="control-label">¿El paciente padece de Alzheimer?<span class="text-danger">
                                        *</span></label>
                                <div class="form-control">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="alzheimerS" id="alzheimerS"
                                            value="1" ngModel #alzheimer="ngModel" [(ngModel)]="cliente.alzheimer">
                                        <label class="form-check-label" for="alzheimerS">Sí</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="alzheimerN" id="alzheimerN"
                                            value="0" ngModel #alzheimer="ngModel" [(ngModel)]="cliente.alzheimer">
                                        <label class="form-check-label" for="alzheimerN">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>-->

                        <div class="col-md-6">
                            <label for="enfermedadesRecientes" class="form-label">Enfermedades anteriores<span
                                    class="text-danger">
                                    *</span></label>
                            <textarea name="enfermedadesRecientes" class="form-control" ngModel
                                #enfermedadesRecientes="ngModel" [(ngModel)]="cliente.enfermedadesRecientes"
                                rows="5" maxlength="65535"></textarea>
                        </div>
                    </div>
                </div>
                <div class="form-container">

                    <div class="dynamic-stuff">
                        <!-- Dynamic element will be cloned here -->
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-12"><span class="font-italic">Los campos con </span><span
                            class="text-danger font-italic">*</span><span class="font-italic"> son obligatorios</span>
                    </div>
                </div>

            </div>
        </mat-tab>
        <mat-tab label="Cirugias y Accidentes">
            <div class="container mt-5 bg-white rounded-top shadow">
                <h4 class="pt-4 pb-1">Cirugias y Accidentes</h4>
                <h6 class="pb-1">Favor de capturar cada una de las cirugias y accidentes del paciente en los campos
                    adecuados</h6>
                <div class="form-group dynamic-element">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="cirugiasRecientes" class="form-label">Cirugias anteriores<span
                                    class="text-danger">
                                    *</span></label>
                            <textarea name="cirugiasRecientes" class="form-control" ngModel #cirugiasRecientes="ngModel"
                                [(ngModel)]="cliente.cirugiasRecientes" rows="5" maxlength="65535"></textarea>

                        </div>

                        <div class="col-md-6">
                            <label for="accidentesRecientes" class="form-label">Accidentes anteriores<span
                                    class="text-danger">
                                    *</span></label>
                            <textarea name="accidentesRecientes" class="form-control" ngModel
                                #accidentesRecientes="ngModel" [(ngModel)]="cliente.accidentesRecientes"
                                rows="5" maxlength="65535"></textarea>
                        </div>
                    </div>
                </div>
                <div class="form-container">
                    <div class="dynamic-stuff">
                        <!-- Dynamic element will be cloned here -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12"><span class="font-italic">Los campos con </span><span
                            class="text-danger font-italic">*</span><span class="font-italic"> son obligatorios</span>
                    </div>
                </div>
                <!-- <div class="row text-center pb-4">
                    <div class="col">
                        <button *ngIf="!idCliente" type="button" class="btn btn-primary"
                            (click)="guardarCliente(myForm)"> Enviar Datos
                        </button>
                        <button *ngIf="idCliente" type="button" class="btn btn-primary"
                            (click)="editarCliente(myForm)">Editar Datos</button>
                    </div>
                </div> -->
            </div>
        </mat-tab>
        <mat-tab label="Información Cliente">
            <div class="container mt-5 bg-white rounded-top shadow">
                <h4 class="pt-4 pb-1">Información Cliente</h4>
                <!-- <div class="row mt-4 pb-4">
                    <div class="col-md-4">
                        <div class="form-check-inline">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input" id="checkboxUsuarioF" value="" [checked]="clienteExistente" (change)="onClienteExistente()">¿Cliente existente?
                            </label>
                          </div>
                    </div>
                    
                </div> -->
                <!-- <div class="row pb-5" *ngIf="clienteExistente">
                    <div class="col-md-5">
                        <ng-multiselect-dropdown name="usuariosFacturacionList" [placeholder]="'Seleccionar'" 
                        [settings]="usuariosFacturacionSettings" [data]="usuariosFacturacion" [(ngModel)]="selectedItems"
                        (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                    </div>
                    
                </div> -->
                <div class="row mt-4 pb-4">
                    <div class="col-md-4">
                        <label for="correoelectronicocliente" class="form-label">Correo Electrónico<span class="text-danger">
                                *</span></label>
                        <input type="email" class="form-control" name="correoelectronicocliente" ngModel
                            #correoelectronicocliente="ngModel" [(ngModel)]="usuarioFacturacion.correoElectronico"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
                            <div *ngIf="correoelectronicocliente.invalid && (correoelectronicocliente.dirty || correoelectronicocliente.touched)" class="alert">
                                <div *ngIf="correoelectronicocliente.errors?.required">
                                    El Correo electronico es obligatorio.
                                </div>
                            </div>
                    </div>
                    <div class="col-md-2">
                        <button type="button" class="btn btn-primary mt-4" (click)="onValidar()">Validar Correo</button>
                    </div>
                    <div class="col-md-6" *ngIf="validacionCorreoFlag">
                        <h6 class="mt-4" *ngIf="!clienteExistente">Cliente no registrado, favor de llenar el formulario</h6>
                        <h6 class="mt-4" *ngIf="clienteExistente">Cliente existente</h6>
                    </div>
                    <div class="col-md-4">
                        <button type="button" class="btn btn-primary mt-4" *ngIf="clienteExistente" (click)="onEditarInformacion()">Editar Información</button>
                    </div>
                </div>

                <div class="row" >
                    <div class="col">
                        <label for="nombrecliente" class="form-label">Nombre(s)<span class="text-danger"> *</span></label>
                        <input type="text" class="form-control valGlobal" id="nombrecliente" name="nombrecliente" ngModel
                            #nombrecliente="ngModel" [(ngModel)]="usuarioFacturacion.nombre" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+" required [disabled]="!botonValidarFlag">
                            <div *ngIf="nombrecliente.invalid && (nombrecliente.dirty || nombrecliente.touched)" class="alert">
                                <div *ngIf="nombrecliente.errors?.required">
                                    El Nombre es obligatorio.
                                </div>
                            </div>
                    </div>

                    <div class="col">
                        <label for="apaternocliente" class="form-label">Apellido Paterno<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control valGlobal" id="apaternocliente" name="apaternocliente"
                            #apaternocliente="ngModel" [(ngModel)]="usuarioFacturacion.a_paterno" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+" required [disabled]="!botonValidarFlag">
                            <div *ngIf="apaternocliente.invalid && (apaternocliente.dirty || apaternocliente.touched)" class="alert">
                                <div *ngIf="apaternocliente.errors?.required">
                                    El Apellido paterno es obligatorio.
                                </div>
                            </div>
                    </div>

                    <div class="col">
                        <label for="amaternocliente" class="form-label">Apellido Materno<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control" id="amaternocliente" name="amaternocliente" ngModel
                            #amaternocliente="ngModel" [(ngModel)]="usuarioFacturacion.a_materno" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]+" required [disabled]="!botonValidarFlag">
                            <div *ngIf="amaternocliente.invalid && (amaternocliente.dirty || amaternocliente.touched)" class="alert">
                                <div *ngIf="amaternocliente.errors?.required">
                                    El Apellido materno es obligatorio.
                                </div>
                            </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-2">
                        <label for="telefonocliente" class="form-label">Teléfono<span class="text-danger"> *</span></label>
                        <input maxlength="10" size="10" type="tel" class="form-control" name="telefonocliente" ngModel
                            #telefonocliente="ngModel" [(ngModel)]="usuarioFacturacion.telefono" placeholder="10 Dígitos"
                            pattern="[0-9]*" minlength="10" required [disabled]="!botonValidarFlag">
                            <div *ngIf="telefonocliente.invalid && (telefonocliente.dirty || telefonocliente.touched)" class="alert">
                                <div *ngIf="telefonocliente.errors?.required">
                                    El Telefono es obligatorio.
                                </div>
                                  <div *ngIf="!telefonocliente.errors?.required && telefonocliente.errors?.minlength">
                                    El Telefono debe contener 10 digitos.
                                  </div>
                              </div>
                    </div>
                    
                </div>

                <hr class="py-1"  />
                <h4 class="pb-1" >Dirección</h4>
                <div class="row" >
                    <div class="col">
                        <label for="calle1cliente" class="form-label">Calle<span class="text-danger"> *</span></label>
                        <input type="text" class="form-control" id="calle1cliente" name="calle1cliente" ngModel #calle1cliente="ngModel"
                            [(ngModel)]="usuarioFacturacion.calle1" [disabled]="!botonValidarFlag">
                    </div>

                    <div class="col">
                        <label for="calle2cliente" class="form-label">Calle 2</label>
                        <input type="text" class="form-control" id="calle1" name="calle2cliente" ngModel #calle2cliente="ngModel"
                            [(ngModel)]="usuarioFacturacion.calle2" [disabled]="!botonValidarFlag">
                    </div>
                </div>

                <div class="row mt-4" >
                    <div class="col-md-2">
                        <label for="noExtcliente" class="form-label">Número Exterior<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control" name="noExtcliente" ngModel #noExtcliente="ngModel"
                            [(ngModel)]="usuarioFacturacion.noExt" [disabled]="!botonValidarFlag">
                    </div>

                    <div class="col-md-2">
                        <label for="noIntcliente" class="form-label">Número Interior</label>
                        <input type="text" class="form-control" name="noIntcliente" ngModel #noIntcliente="ngModel"
                            [(ngModel)]="usuarioFacturacion.noInt" [disabled]="!botonValidarFlag">
                    </div>

                    <div class="col-md-2">
                        <label for="codigoPostalcliente" class="form-label">Código Postal<span class="text-danger">
                                *</span></label>
                        <input type="text" class="form-control" maxlength="5" name="codigoPostalcliente"
                            [ngModel]="selectedCodigoPostalcliente" (change)="onCodigoPostalCliente($event.target.value)" ngModel
                            #codigoPostalcliente="ngModel" pattern="[0-9]*" [disabled]="!botonValidarFlag">

                    </div>

                    <div class="col">
                        <label for="coloniacliente" class="form-label">Colonia<span class="text-danger"> *</span></label>
                        <select class="form-control" name="coloniacliente" ngModel #coloniacliente="ngModel"
                        [(ngModel)]="usuarioFacturacion.idColonia" (change)="onColoniaCliente($event.target.value)" [disabled]="!botonValidarFlag">
                            <option *ngFor="let coloniacliente of coloniascliente" [value]="coloniacliente.idColonia">
                                {{coloniacliente.nombre}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row mt-4" >
                    <div class="col">
                        <label for="ciudadcliente" class="form-label">Ciudad<span class="text-danger"> *</span></label>
                        <select class="form-control" name="ciudadcliente" (change)="onCiudadCliente($event.target.value)" [disabled]="!botonValidarFlag">
                            <option *ngFor="let ciudadcliente of ciudadescliente" [value]="ciudadcliente.idCiudad">
                                {{ciudadcliente.nombre}}
                            </option>
                        </select>
                    </div>

                    <div class="col">
                        <label for="estadocliente" class="form-label">Estado<span class="text-danger"> *</span></label>
                        <select class="form-control" name="estadocliente" (change)="onEstadoCliente($event.target.value)" [disabled]="!botonValidarFlag">
                            <option *ngFor="let estadocliente of estadoscliente" [value]="estadocliente.idEstado">
                                {{estadocliente.nombre}}
                            </option>
                        </select>
                    </div>

                    <div class="col">
                        <label for="paiscliente" class="form-label">País<span class="text-danger"> *</span></label>
                        <select class="form-control" name="paiscliente" [disabled]="!botonValidarFlag">
                            <option *ngFor="let paiscliente of paisescliente" [value]="paiscliente.idPais">
                                {{paiscliente.nombre}}
                            </option>
                        </select>
                    </div>

                </div>

                <hr class="py-1" />
                <h4 class="pb-1" >Información Bancaria</h4>
                <div class="row" >
                    <div class="col-md-3">
                        <label for="bancocliente" class="form-label">Banco<span class="text-danger"> *</span></label>
                        <select class="form-control" name="bancocliente" ngModel #bancocliente="ngModel" [(ngModel)]="usuarioFacturacion.idBanco" (change)="onBancoCliente($event.target.value)" [disabled]="!botonValidarFlag">
                          <option value="" selected disabled hidden>Seleccionar</option>
                          <option *ngFor="let bancocliente of bancoscliente" [value]="bancocliente.idBanco">
                            {{bancocliente.nombre}}
                          </option>
                        </select>
                    </div>

                    <div class="col-md-3">
                        <label for="tipocuentacliente" class="form-label">Tipo de Cuenta<span class="text-danger"> *</span></label>
                        <select class="form-control" name="tipocuentacliente" ngModel #tipocuentacliente="ngModel" [(ngModel)]="usuarioFacturacion.tipoCuenta"
                          (change)="onTipoCuentaCliente($event.target.value)" [disabled]="!botonValidarFlag">
                          <option value="" selected disabled hidden>Seleccionar</option>
                          <option value="CLABE">CLABE</option>
                          <option value="Numero de Tarjeta">Numero de Tarjeta</option>
                        </select>
                    </div>

                    <div class="col-md-3">
                        <label for="numerocuentacliente" class="form-label">Numero<span class="text-danger"> *</span></label>
                        <input type="text" class="form-control" name="numerocuentacliente" [(ngModel)]="usuarioFacturacion.numeroCuenta" ngModel
                          #numerocuentacliente="ngModel" [disabled]="!botonValidarFlag">
                    </div>
                </div>

                <div class="row pb-4">
                    <div class="col-md-12"><span class="font-italic">Los campos con </span><span
                            class="text-danger font-italic">*</span><span class="font-italic"> son obligatorios</span>
                    </div>
                </div>

                <div class="row text-center pb-4">
                    <div class="col">
                        <button *ngIf="!idCliente" type="button" class="btn btn-primary"
                            (click)="guardarCliente(myForm)"> Enviar Datos
                        </button>
                        <button *ngIf="idCliente" type="button" class="btn btn-primary"
                            (click)="editarCliente(myForm)">Editar Datos</button>
                        <!-- Comentar boton de arriba al finalizar. Descomentar el boton de abajo para que inhabilite el boton si existe algun error en algun campo. -->
                        <!-- <button type="button" [disabled]="!myForm.form.valid" class="btn btn-primary" (click)="guardarColaborador(myForm)"> Grabar Colaborador </button> -->
                    </div>
                </div>

            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="container bg-white rounded-bottom shadow">
        <div class="row text-center pb-4">

            <div class="col">
                <div class="btn-toolbar" role="toolbar" style="justify-content: center; display: flex;">
                    <div class="btn-group mr-2">
                        <button type="button" class="btn btn-primary" ngDefaultControl [formControl]="selected"
                            (click)="pagAtras(1)">&lt;</button>
                    </div>
                    <div class="btn-group mr-2">
                        <button type="button" class="btn btn-primary" ngDefaultControl [formControl]="selected"
                            (click)="pagDelante(1)">&gt;</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>