
<div class="container mt-5 bg-white rounded shadow">
    <h2 class="pt-4 pb-1">Colaboradores</h2>
    <div class="row pb-4">
        <div class="col-md-12 text-center">
            <button type="button" class="btn btn-success btn-lg mx-5" (click)="botonAltaColaboradores()"><h1 style=font-size:4em><fa-icon [icon]="faUserNurse"></fa-icon></h1> Alta de Colaboradores</button>
            <button type="button" class="btn btn-warning btn-lg mx-5" (click)="botonConsultaColaboradores()"><h1 style=font-size:4em><fa-icon [icon]="faBookMedical"></fa-icon></h1> Consulta de Colaboradores</button>
        </div>
    </div>
    <hr/>
    <h2 class="pt-4 pb-1">Clientes</h2>
    <div class="row pb-4">
        <div class="col-md-12 text-center">
                <button type="button" class="btn btn-success btn-lg mx-5" (click)="botonAltaClientesF()"><h1 style=font-size:4em><fa-icon [icon]="faUserInjured"></fa-icon></h1> Alta de Pacientes</button>
                <button type="button" class="btn btn-success btn-lg " (click)="botonAltaClientesM()"><h1 style=font-size:4em><fa-icon [icon]="faBriefcase"></fa-icon></h1> Alta de Clientes Morales</button> 
                <button type="button" class="btn btn-warning btn-lg mx-5" (click)="botonConsultaClientes()"><h1 style=font-size:4em><fa-icon [icon]="faAddressBook"></fa-icon></h1> Gestión de Pacientes</button>
        </div>
    </div>
    <hr/>

    <h2 class="pt-4 pb-1">Administración</h2>
    <div class="row pb-4">
        <div class="col-md-12 text-center">
            <button type="button" class="btn btn-warning btn-lg mx-5" (click)="botonAltaServicios()"><h1 style=font-size:4em><fa-icon [icon]="faBriefcaseMedical"></fa-icon></h1> Alta de Servicios</button>
            <button type="button" class="btn btn-warning btn-lg mx-5" (click)="botonGestionServicios()"><h1 style=font-size:4em><fa-icon [icon]="faBriefcaseMedical"></fa-icon></h1> Gestión de Servicios</button>
            <button type="button" class="btn btn-danger btn-lg mx-5"><h1 style=font-size:4em><fa-icon [icon]="faUserCog"></fa-icon></h1> Gestión de Pacientes</button>
        </div>
    </div>
    <div class="row pb-4">
        <div class="col-md-12 text-center">
            <button type="button" class="btn btn-warning btn-lg mx-5" (click)="botonPagoPaciente()"><h1 style=font-size:4em><fa-icon [icon]="faDollarSign"></fa-icon></h1>Registrar Pago</button>
            <button type="button" class="btn btn-warning btn-lg mx-5"  (click)="botonGestionPago()"><h1 style=font-size:4em><fa-icon [icon]="faFunnelDollar"></fa-icon></h1>Gestion y Reportes de Pagos</button>
        </div>
    </div>
</div>