export class Cliente {
    public idCliente: number;
    public nombre: string;
    public a_paterno: string;
    public a_materno: string;
    public correoElectronico: string;
    public rfc: string;
    public fecha_nacimiento: Date;
    public idSexo: number;
    public peso: number;
    public estatura: number;
    public idEstadoCivil: number;
    public idComplexion: number;
    public telefono: string;
    public idTipoTelefono: number;
    public telefono2: string;
    public idTipoTelefono2: number;
    public sgmm: string;
    public imss: string;
    public calle1: string;
    public calle2: string;
    public codigoPostal: number;
    public idPaisNacimiento: number;
    public idEstadoNacimiento: number;
    public idCiudadNacimiento: number;
    public idPais: number;
    public idEstado: number;
    public idCiudad: number;
    public idColonia: number;
    public noExt: string;
    public noInt: string;
    public referencia: string;
    public nombreContacto: string;
    public idParentescoContacto: number;
    public telefonoContacto: number;
    public correoContacto: number;
    public nombreContacto2: string;
    public idParentescoContacto2: number;
    public telefonoContacto2: number;
    public correoContacto2: number;
    public nombreMedico: string;
    public especialidadesMedico: string;
    public telefonoMedico: number;
    public correoMedico: number;
    public enfermedadesActuales: string;
    public enfermedadesRecientes: number;
    public cirugiasRecientes: number;
    public accidentesRecientes: number;
    public alzheimer: boolean;
    public idTipoCliente: boolean;
    public contactosCliente: any[];

    public idUsuarioFacturacion: number;
    public accionInfoCliente: boolean;
    public nombrecliente: string;
    public a_paternocliente: string;
    public a_maternocliente: string;
    public telefonocliente: string;
    public correoelectronicocliente: string;
    public calle1cliente: string;
    public calle2cliente: string;
    public noExtcliente: string;
    public noIntcliente: string;
    public codigoPostalcliente: number;
    public idColoniacliente: number;
    public idCiudadcliente: number;
    public idEstadocliente: number;
    public idPaiscliente: number;
    public idBancocliente: number;
    public tipoCuentacliente: string;
    public numerocuentacliente: number;
    public clienteExistente: boolean;
    public clienteExistenteSelected: number;

    public usuarioFacturacion: any;

    constructor() {
    }
}