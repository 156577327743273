
<div class="container mt-5 pt-4 bg-white rounded-top shadow">
     <button type="button" class="btn btn-success mb-4" (click)="agregarClienteFisico()" ><h3><fa-icon [icon]="faUserNurse"></fa-icon></h3> Agregar Paciente</button> &nbsp;&nbsp;
     <button type="button" class="btn btn-success mb-4" (click)="agregarClienteMoral()" ><h3><fa-icon [icon]="faUserNurse"></fa-icon></h3> Agregar Persona Moral</button> &nbsp;&nbsp;

     <table class="table">
      
      <tbody>
        <tr>
          
         

          <th scope="row">Tipo de Cliente</th>
        <td>
          <div class="input-group">
          <select class="form-control"  name="tipoClienteFiltro" >
            <option value="" selected disabled hidden>Seleccionar</option>
            <option *ngFor="let tipoCliente of tipoClientes" [value]="tipoCliente.idTipoCliente">
              {{tipoCliente.nombre}}
            </option>
          </select>
          </div>
        </td>
        <th scope="row">Correo Electronico</th>
        <td>
          <input maxlength="3" size="3" type="text" class="form-control" id="correoElectronico" name="correoElectronico" 
            >
        </td>
        <th scope="row">Nombre</th>
        <td>
          <input maxlength="3" size="3" type="text" class="form-control" id="nombre" name="nombre" 
            >
        </td>
        
        </tr>
       
        
         
      </tbody>
    </table> 
  
  
    <div class="row text-center pb-4">
      <div class="col">
        <button type="button" class="btn btn-primary" >Buscar</button> &nbsp;&nbsp;
      
        </div>
    </div>
  </div>
  
  
  
  <div class="container bg-white rounded-bottom shadow pb-4">
    <!-- <div class="container"> -->
    <div class="mat-elevation-z8">
      <table mat-table matTableExporter [dataSource]="clienteSource" class="mat-elevation-z8"
        #exporter="matTableExporter">
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
          <td mat-cell *matCellDef="let element"> {{element.nombre}} {{element.a_paterno}} {{element.a_materno}} </td>
        </ng-container>             
        <ng-container matColumnDef="telefono">
          <th mat-header-cell *matHeaderCellDef> Telefono </th>
          <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
        </ng-container>
        <ng-container matColumnDef="correoElectronico">
          <th mat-header-cell *matHeaderCellDef> Correo</th>
          <td mat-cell *matCellDef="let element"> {{element.correoElectronico}}  </td>
        </ng-container>      
        <ng-container matColumnDef="idTipoCliente">
          <th mat-header-cell *matHeaderCellDef> Tipo de Cliente</th>
          <td mat-cell *matCellDef="let element"> {{element.idTipoCliente}}  </td>
        </ng-container>   
       <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef> Acciones </th>
          <td mat-cell *matCellDef="let element">
            <button class="btn btn-primary" (click)="openDialog(element.idCliente)"><mat-icon>search</mat-icon></button>

                      <button class="btn btn-warning" (click)="modificacionCliente(element.idCliente, element.idTipoCliente )"><mat-icon>edit</mat-icon></button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="clienteColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: clienteColumns;"></tr>
      </table>
      <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[1, 5, 25, 100]"
         aria-label="Selecciona los elementos a mostrar">
      </mat-paginator>
    </div>
    <br>
    <div class="row text-center pb-4">
      <div class="col">
        <button class="btn btn-success" >Exportar como Excel</button>
      </div>
    </div>
    
  </div>
