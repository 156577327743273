
export class ServicioFiltro {
    
    public fecha1:Date;
    public fecha2:Date;
    public colaborador:any[];
    public estatus:string;
    public estatusOperativo: number;
    public estatusPago: number;
    public limit:number;
    public start:number;
    public responsables:any[];
    public pacientes:any[];
    constructor() {
    }
}            