export class EstadoCuentaFiltro {
    
    public fecha1:Date;
    public fecha2:Date;

    public pacientes:any[];


    constructor() {
    }
}            