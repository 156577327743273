<div class="container mt-5 pt-4 bg-white rounded-top shadow">
    <h4 class="pt-1 pb-1" class="text-center">Reporte Antiguedad de Saldos</h4>
    <div class="row text-center pb-4">
      </div>
    </div>
    
  
    <div class="container bg-white rounded-bottom shadow pb-4">
      <div>
          <table class="table table-bordered" id="tablaAntiguedadSaldos">
            <thead class="tableHeader">
                <tr>
                    <th colspan="6" style="background: white;"></th>
                    <th scope="col" colspan="3">Pendiente por Pagar</th>
                </tr>
              <tr>
                <th scope="col">ID Servicio</th>
                <th scope="col">Paciente</th>
                <th scope="col">Tipo de Servicio</th>
                <th scope="col">Fecha alta Servicio</th>
                <th scope="col">Precio</th>
                <th scope="col">Pagos</th>
                <th scope="col">1-7 días</th>
                <th scope="col">8-14 días</th>
                <th scope="col">+15 días</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of deudores;">
              <tr *ngIf="!item.subTotal" scope="row">
                <td>{{item.idServicio}}</td>
                <td>{{item.nombre + " " + item.a_paterno + " " + item.a_materno}}</td>
                <td>{{item.nombreTipoServicio}}</td>
                <td>{{item.fechaCreacion}}</td>
                <td>{{item.precioServicio | currency}}</td>
                <td>{{item.cantidadPagada | currency}}</td>
                <td *ngIf="item.pendienteUnDia>0; else pendienteUnF">{{item.pendienteUnDia | currency}}</td>
                <ng-template #pendienteUnF><td></td></ng-template>
                <td *ngIf="item.pendienteOchoDias>0; else pendienteOchoF">{{item.pendienteOchoDias | currency}}</td>
                <ng-template #pendienteOchoF><td></td></ng-template>
                <td *ngIf="item.pendienteMasQuinceDias>0; else pendienteMasQuinceF">{{item.pendienteMasQuinceDias | currency}}</td>
                <ng-template #pendienteMasQuinceF><td></td></ng-template>
              </tr>
              <tr *ngIf="item.subTotal" scope="row">
                <td>{{item.idServicio}}</td>
                <td>{{item.nombre + " " + item.a_paterno + " " + item.a_materno}}</td>
                <td>{{item.nombreTipoServicio}}</td>
                <td>{{item.fechaCreacion}}</td>
                <td>{{item.precioServicio | currency}}</td>
                <td>{{item.cantidadPagada | currency}}</td>
                <td *ngIf="item.pendienteUnDia>0; else pendienteUnF">{{item.pendienteUnDia | currency}}</td>
                <ng-template #pendienteUnF><td></td></ng-template>
                <td *ngIf="item.pendienteOchoDias>0; else pendienteOchoF">{{item.pendienteOchoDias | currency}}</td>
                <ng-template #pendienteOchoF><td></td></ng-template>
                <td *ngIf="item.pendienteMasQuinceDias>0; else pendienteMasQuinceF">{{item.pendienteMasQuinceDias | currency}}</td>
                <ng-template #pendienteMasQuinceF><td></td></ng-template>
              </tr>
              <tr *ngIf="item.subTotal" scope="row" class="subTotal">
                <th>Subtotal:</th>
                <td></td>
                <td></td>
                <td></td>
                <th>{{item.precioSubTotal | currency}}</th>
                <th>{{item.pagosSubTotal | currency}}</th>
                <td *ngIf="item.unDiaSubTotal>0; else unF">{{item.unDiaSubTotal | currency}}</td>
                <ng-template #unF><td>-</td></ng-template>
                <!-- <th>{{item.unDiaSubTotal | currency}}</th> -->
                <td *ngIf="item.ochoDiasSubTotal>0; else ochoF">{{item.ochoDiasSubTotal | currency}}</td>
                <ng-template #ochoF><td>-</td></ng-template>
                <td *ngIf="item.masQuinceDiasSubTotal>0; else masQuinceF">{{item.masQuinceDiasSubTotal | currency}}</td>
                <ng-template #masQuinceF><td>-</td></ng-template>
                <!-- <th>{{item.ochoDiasSubTotal | currency}}</th>
                <th>{{item.masQuinceDiasSubTotal | currency}}</th> -->
              </tr>
              <tr *ngIf="item.ultimo" scope="row"><td colspan="9"></td></tr>
              <tr *ngIf="item.ultimo" scope="row" class="total">
                <td>Gran Total:</td>
                <th>{{granTotal | currency}}</th>
                <td colspan="4"></td>
                <th>{{unDiaSumaTotal | currency}}</th>
                <th>{{ochoDiasSumaTotal | currency}}</th>
                <th>{{masQuinceDiasSumaTotal | currency}}</th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row text-center pb-4">
            <div class="col">
              <button class="btn excelColor" (click)="exportTableToExcel()"><fa-icon [icon]="faFileExcel"></fa-icon> Exportar como Excel</button>
            </div>
          </div>
    </div>