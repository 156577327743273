import { Estudio } from "./estudio";

export class Colaborador {
    public idColaborador: number;
    public nombre: string;
    public a_paterno: string;
    public a_materno: string;
    public idCalificacion: number;
    public idGradoEstudios: number;
    public idTipoVisa: number;
    public idTipoColaborador: number;
    public idEstadoNacimiento: number;
    public correoElectronico: string;
    public hijos: boolean;
    hijosViven: boolean;
    public foto: any;
    public fotoNombre: string;
    public rfc: string;
    public curp: string;
    public nss: string;
    public fecha_nacimiento: Date;
    public idSexo: number;
    public peso: number;
    public estatura: number;
    public zonas: any[];
    public idEstadoCivil: number;
    public idTez: number;
    public telefono: string;
    public idTipoTelefono: number;
    public telefono2: string;
    public idTipoTelefono2: number;
    public sgmm: string;
    public atiendeCovid: boolean;
    public antecedentePenales: boolean;
    public autoPropio: boolean;
    public licenciaManejar: boolean;
    public dispuestoViajar: boolean;
    public visa: boolean;
    public visaNumero: string;
    public tipoVisa: string;
    public expiracionVisa: Date;
    public visaImagen: any;
    public visaNombre: any;
    public pasaporte: boolean;
    public pasaporteNumero: string;
    public expiracionPasaporte: Date;
    public pasaporteImagen: any;
    public hacerComer: boolean;
    public limpiarUtensiliosCocina: boolean;
    public limpiarDormitorio: boolean;
    public limpiarBano: boolean;
    public ayudaPaciente: boolean;
    public pasaporteNombre: any;
    public ine1: any;
    public ine2: any;
    public ine1Nombre: string;
    public ine2Nombre: string;
    public idEstatus: number;
    public calle1: string;
    public calle2: string;
    public codigoPostal: number;
    public idPaisNacimiento: number;
    public idCiudadNacimiento: number;
    public idPais: number;
    public idEstado: number;
    public idCiudad: number;
    public idColonia: number;
    public noExt: string;
    public noInt: string;
    public comprobanteDomicilio: any;
    public comprobanteNombre: string;
    public horario: any;
    public estudios: any[];
    public experiencias: any[];
    public historialServicios: any[];
    public cuentasColaborador: any[];
    public contactosColaborador: any[];
    public idPermanencia: number;
    public referenciaDireccion: string;
    public observaciones: string;
    public especialidades: any[];
    public habilidades: any[];
    public idGradoEstudio: number;
    public cvFile: any;
    public cvNombre: any;
    constructor() {
    }
}