<form style="background: #00D8D6; background: linear-gradient(to right, #00D8D6, #6EC1E4);" #myForm="ngForm">
  <ngb-toast-container></ngb-toast-container>
  <mat-tab-group mat-align-tabs="center" [selectedIndex]="selected.value"
    (selectedIndexChange)="selected.setValue($event)">
   
    <mat-tab label="Servicio">
      <div class="container mt-5 bg-white rounded-top shadow">
        <h4 class="pt-4 pb-1">Información del Cliente</h4>
        <div class="row">
          <div class="col-md-6">
            <ng-multiselect-dropdown name="cliente" [placeholder]="'Seleccionar'" [settings]="clientesSettings"
              [data]="clientes" [(ngModel)]="selectedItems" (onSelect)="onSelectedCliente($event)" (onDeSelect)="onDeSelectedCliente($event)">
            </ng-multiselect-dropdown>
          </div>
        </div>
        <h4 class="pt-4 pb-1">Información del Servicio</h4>
        
        <div class="row pb-4">
          <div class="col-md-2">
            <label for="tipoServicio" class="form-label">Tipo de Servicio</label>
            <select class="form-control" name="tipoServicio" #tipoServicio="ngModel"
              [(ngModel)]="servicio.idTipoServicio" ngModel (change)="onTipoServicio($event.target.value)">
              <option value="" selected disabled hidden>Seleccionar</option>
              <option *ngFor="let tipoServicio of tiposServicios" [value]="tipoServicio.idTipoColaborador">
                {{tipoServicio.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <label for="responsable" class="form-label">Responsable<span class="text-danger"> *</span></label>
            <select class="form-control" name="responsable" ngModel #responsable="ngModel"
              [ngModel]="servicio.idResponsable" (change)="onResponsable($event.target.value)">
              <option value="" selected disabled hidden>Seleccionar</option>
              <option *ngFor="let responsable of responsables" [value]="responsable.idResponsable">
                {{responsable.nombre}}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <label for="fechaTerminacion" class="form-label">Fecha de Terminación<span class="text-danger">*</span></label>
            <input type="date" class="form-control" (change)="onFechaChange($event.target.value)" id="fechaTerminacion" name="fechaTerminacion" ngModel #fechaTerminacion="ngModel" [(ngModel)]="servicio.fechaTerminacion" value="yyyy-MM-dd">
            <div class="col-md-1"><fa-icon [icon]="faQuestionCircle" style="color: gray;" [matTooltip]="dia" [matTooltipPosition]="position.value"></fa-icon></div>
          </div>
          <div class="col">
            <label for="semanaAlta" class="form-label">Semana del Servicio</label>
            <select class="form-control" name="semanaAlta" #semanaAlta="ngModel" [(ngModel)]="servicio.semanaAlta" ngModel>
              <option *ngFor='let in of counter(52) ;let i = index' [value]="i+1">
                {{i+1}}
              </option>
            </select>
            <!--<input type="text" class="form-control" id="estatus" name="estatus" onkeydown="return false"
              value="{{servicio.estatus}}">-->
          </div>
          <div class="col-md-3" *ngIf="isSelected">
            <button type="button" class="btn btn-success btn-sm my-4" (click)="onCopiar()">Copiar Servicio</button>
          </div>
        </div>

        <div class="row pb-4" *ngIf="!isSelected">
          <div class="col">
            <label for="precioServicio" class="form-label">Precio</label>
            <input type="number" class="form-control valGlobal" placeholder="$" id="precioServicio"
              name="precioServicio" step=".01" ngModel #precioServicio="ngModel" [(ngModel)]="servicio.precioServicio"
              (change)="onPrecioChange()" *ngIf="!tienePrecio">
              <input type="number" class="form-control valGlobal" placeholder="$" id="precioServicio"
              name="precioServicio" step=".01" ngModel #precioServicio="ngModel" [(ngModel)]="servicio.precioServicio"
              (change)="onPrecioChange()" *ngIf="tienePrecio" disabled>
          </div>
          <p class="my-auto">
            <fa-icon [icon]="faMinus"></fa-icon>
          </p>
          <div class="col">
            <label for="cantidadPagada" class="form-label">Cantidad Pagada</label>
            <input type="number" class="form-control valGlobal" placeholder="$" id="cantidadPagada"
              name="cantidadPagada" step=".01" ngModel #precioServicio="ngModel" [(ngModel)]="servicio.cantidadPagada"
              (change)="onPrecioChange()" disabled>
          </div>
          <p class="my-auto">
            <fa-icon [icon]="faEquals"></fa-icon>
          </p>
          <div class="col">
            <label for="porPagar" class="form-label">Cantidad Por Pagar</label>
            <input type="text" class="form-control valGlobal" placeholder="$" id="porPagar" name="porPagar"
              onkeydown="return false" value="{{servicio.cantidadPorPagar}}" disabled>
          </div>
        </div>

        <div class="row pb-4" *ngIf="isSelected">
          <div class="col">
            <label for="precioServicio" class="form-label">Precio</label>
            <!-- <input type="number" class="form-control valGlobal" placeholder="$" id="precioServicio"
              name="precioServicio" step=".01" ngModel #precioServicio="ngModel" [(ngModel)]="servicio.precioServicio"
              (change)="onPrecioChange()"> -->
              <input type="number" class="form-control valGlobal" placeholder="$" id="precioServicio"
              name="precioServicio" step=".01" ngModel #precioServicio="ngModel" [(ngModel)]="servicio.precioServicio"
              (change)="onPrecioChange()" *ngIf="!tienePrecio">
              <input type="number" class="form-control valGlobal" placeholder="$" id="precioServicio"
              name="precioServicio" step=".01" ngModel #precioServicio="ngModel" [(ngModel)]="servicio.precioServicio"
              (change)="onPrecioChange()" *ngIf="tienePrecio" disabled>
          </div>
          <p class="my-auto">
            <fa-icon [icon]="faMinus"></fa-icon>
          </p>
          <div class="col">
            <label for="cantidadPagada" class="form-label">Cantidad Pagada</label>
            <input type="number" class="form-control valGlobal" placeholder="$" id="cantidadPagada"
              name="cantidadPagada" step=".01" ngModel #precioServicio="ngModel" [(ngModel)]="servicio.cantidadPagada"
              (change)="onPrecioChange()" disabled>
          </div>
          <p class="my-auto">
            <fa-icon [icon]="faEquals"></fa-icon>
          </p>
          <div class="col">
            <label for="porPagar" class="form-label">Cantidad Por Pagar</label>
            <input type="text" class="form-control valGlobal" placeholder="$" id="porPagar" name="porPagar"
              onkeydown="return false" value="{{servicio.cantidadPorPagar}}" disabled>
          </div>
          <div class="col">
            <button type="button" class="btn btn-success btn-sm my-4" (click)="registrarPago()">Registrar Pago</button>
          </div>
        </div>

        <div class="row pb-4">
          <div class="col-md-2">
            <label for="edad" class="form-label">Num. Colaboradores Requeridos</label>
            <input type="text" class="form-control" id="colabReq" name="colabReq" ngModel #colabReq="ngModel"
              [(ngModel)]="servicio.colabReq" (change)="onNumColab()">
          </div>
          <!-- <div class="col">
            <label class="form-label"><br>Asignar Colaborador</label>
            <ng-multiselect-dropdown name="colaborador" [placeholder]="'Sin Asignar'" [settings]="colaboradoresSettings"
              [data]="colaboradores" (onSelect)="onEstatusSelect($event)" (onDeSelect)="onEstatusDeSelect($event)"
              [(ngModel)]="selectedColaboradorItems">
            </ng-multiselect-dropdown>
          </div> -->
          <div class="col-md-2">
            <button type="button" class="btn btn-success btn-sm my-5" (click)="nuevoColaborador()"> Nuevo Colaborador</button>
          </div>
         <!--<div class="col-md-2">
            <label for="pagoColaborador" class="form-label"><br>Pago a Colaborador</label>
            <input type="number" class="form-control valGlobal" placeholder="$" id="pagoColaborador"
              name="pagoColaborador" step=".01" ngModel #pagoColaborador="ngModel" [(ngModel)]="servicio.pagoColaborador"
              >
          </div>--> 
          <div class="col">
            <label for="estatusOperativo" class="form-label"><br>Estatus Operación</label>
            <select class="form-control" name="estatusOperativo" #estatusOperativo="ngModel" [(ngModel)]="servicio.estatusOperativo" ngModel  
            (change)="onEstatusOperacion($event.target.value)">
              <option *ngFor="let estatusOperativo of estatusOperaciones" [value]="estatusOperativo.idTipoEstatusOperacion">
                {{estatusOperativo.Nombre}}
              </option>
            </select>
            <!--<input type="text" class="form-control" id="estatus" name="estatus" onkeydown="return false"
              value="{{servicio.estatus}}">-->
          </div>
          <div class="col">
            <label for="estatusPago" class="form-label"><br>Estatus Pago</label>
            <select class="form-control" name="estatusPago" #estatusPago="ngModel" [(ngModel)]="servicio.estatusPago" ngModel  
            (change)="onEstatusPago($event.target.value)" disabled>
              <option *ngFor="let estatusPago of estatusPagos" [value]="estatusPago.idTipoEstatusPago">
                {{estatusPago.Nombre}}
              </option>
            </select>
          </div>
        </div>
        <div class="row pb-4">
          <div class="col-md-3">
            <label class="form-label"><br>Asignar Colaborador</label>
            <ng-multiselect-dropdown name="colaborador2" [placeholder]="'Sin Asignar'" [settings]="colaboradoresSettings2"
              [data]="colaboradores" [(ngModel)]="selectedColaboradorItems2">
            </ng-multiselect-dropdown>
          </div>
          <div class="col-md-2">
            <label for="pagoColaborador" class="form-label"><br>Pago a Colaborador</label>
            <input type="number" class="form-control valGlobal" placeholder="$" id="pagoColaborador"
              name="pagoColaborador" step=".01" ngModel #pagoColaborador="ngModel" [(ngModel)]="servicio.pagoColaborador">
          </div>
          <div class="col-md-6">
            <label for="observacionesColaborador" class="form-label"><br>Observación</label>
            <textarea name="observacionesColaborador" id ="observacionesColaborador" class="form-control" rows="3" maxlength="65535" ngModel #observacionesColaborador="ngModel" [(ngModel)]="observacionColaborador"></textarea>
          </div>
          <div class="col-md-1">
            <button type="button" class="btn btn-success btn-sm my-5" (click)="agregarColaborador()"><fa-icon [icon]="faPlus"></fa-icon></button>
          </div>
        </div>
        <hr class="mt-3 mb-3" />
        <h4>Colaboradores Asignados:</h4>
        <div class="row mt-4 pb-4">
          <div class="col-md-12 text-center">
            <table mat-table [dataSource]="colaboradorSource" #colaboradoresTable class="mat-elevation-z8">
              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef> Nombre</th>
                <td mat-cell *matCellDef="let element"> {{element.nombrecompleto}} </td>
              </ng-container>

              <ng-container matColumnDef="sueldo">
                <th mat-header-cell *matHeaderCellDef> Sueldo</th>
                <td mat-cell *matCellDef="let element">$ {{element.sueldo}}
                  <!-- <input type="number" class="form-control valGlobal" placeholder="$" id="pagoColaborador"
              name="pagoColaborador" step=".01" ngModel #pagoColaborador="ngModel" [(ngModel)]="servicio.pagoColaborador"
              >  -->
            </td>
              </ng-container>

              <ng-container matColumnDef="observacion">
                <th mat-header-cell *matHeaderCellDef> Observación</th>
                <td mat-cell *matCellDef="let element">{{element.observacion}}
            </td>
              </ng-container>

              <ng-container matColumnDef="eliminar">
                <th mat-header-cell *matHeaderCellDef>Eliminar</th>
                <td mat-cell *matCellDef="let i = index">
                  <button type="button" class="btn btn-danger" (click)="onEliminarColaborador(i)"><fa-icon [icon]="faTrash"></fa-icon></button>
                  <!-- <input type="number" class="form-control valGlobal" placeholder="$" id="pagoColaborador"
              name="pagoColaborador" step=".01" ngModel #pagoColaborador="ngModel" [(ngModel)]="servicio.pagoColaborador"
              >  -->
            </td>
              </ng-container>


             <tr mat-header-row *matHeaderRowDef="colaboradorColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: colaboradorColumns;"></tr>
            </table>
          </div>
        </div>
        <div class="row pb-4">
          <div class="col-md-12"><span class="font-italic">Los campos con </span><span
              class="text-danger font-italic">*</span><span class="font-italic"> son obligatorios</span></div>
        </div>

        <h4 class="pt-4 pb-1">Ubicación del Servicio</h4>
        <div class="row">
          <div class="col-md-4">
            <button type="button" class="btn btn-success btn-sm mb-4" (click)="onCopiarDir()" *ngIf="servicio.cliente">Copiar dirección del paciente</button>
            <button type="button" class="btn btn-success btn-sm mb-4" *ngIf="!servicio.cliente" disabled>Copiar dirección del paciente</button>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="calle" class="form-label">Calle<span class="text-danger"> *</span></label>
            <input type="text" class="form-control" id="calle1" name="calle1" ngModel #calle1="ngModel"
              [(ngModel)]="servicio.calle1">
          </div>

          <div class="col">
            <label for="calle2" class="form-label">Calle 2</label>
            <input type="text" class="form-control" id="calle1" name="calle2" ngModel #calle2="ngModel"
              [(ngModel)]="servicio.calle2">
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-2">
            <label for="numExterior" class="form-label">Número Exterior<span class="text-danger"> *</span></label>
            <input type="text" class="form-control" name="numExterior" ngModel #numExterior="ngModel"
              [(ngModel)]="servicio.noExt">
          </div>

          <div class="col-md-2">
            <label for="numInterior" class="form-label">Número Interior</label>
            <input type="text" class="form-control" name="numInterior" ngModel #numInterior="ngModel"
              [(ngModel)]="servicio.noInt">
          </div>

          <div class="col-md-2">
            <label for="codigopostal" class="form-label">Código Postal<span class="text-danger"> *</span></label>
            <input type="text" class="form-control" maxlength="5" name="codigopostal" ngModel #codigopostal="ngModel"
              [(ngModel)]="servicio.codigoPostal" [ngModel]="selectedCodigoPostal" pattern="[0-9]*"
              (change)="onCodigoPostal($event.target.value)">
          </div>


          <div class="col">
            <label for="colonia" class="form-label">Colonia<span class="text-danger"> *</span></label>
            <select class="form-control" name="colonia" ngModel #colonia="ngModel" [(ngModel)]="servicio.idColonia"
              (change)="onColonia($event.target.value)">
              <option *ngFor="let colonia of colonias" [value]="colonia.idColonia">
                {{colonia.nombre}}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <label for="ciudad" class="form-label">Ciudad<span class="text-danger"> *</span></label>
            <select class="form-control" name="ciudad" (change)="onCiudad($event.target.value)">
              <option *ngFor="let ciudad of ciudadesDir" [value]="ciudad.idCiudad">
                {{ciudad.nombre}}
              </option>
            </select>
          </div>

          <div class="col">
            <label for="estado" class="form-label">Estado<span class="text-danger"> *</span></label>
            <select class="form-control" name="estado" (change)="onEstado($event.target.value)">
              <option *ngFor="let estado of estadosDir" [value]="estado.idEstado">
                {{estado.nombre}}
              </option>
            </select>
          </div>

          <div class="col">
            <label for="pais" class="form-label">País<span class="text-danger"> *</span></label>
            <select class="form-control" name="pais">
              <option *ngFor="let pais of paises" [value]="pais.idPais">
                {{pais.nombre}}
              </option>
            </select>
          </div>

        </div>

        <div class="row mt-4 pb-4">
          <div class="col-md-6">
            <label for="referencia" class="form-label">Referencia</label>
            <textarea name="referencia" class="form-control" ngModel #referencia="ngModel"
              [(ngModel)]="servicio.referenciaDireccion" rows="3" maxlength="65535"></textarea>
          </div>
        </div>


        <div class="row pb-4">
          <div class="col-md-12"><span class="font-italic">Los campos con </span><span
              class="text-danger font-italic">*</span><span class="font-italic"> son obligatorios</span></div>
        </div>
     
       
      </div>
    </mat-tab>
    <mat-tab label="Información Médica">
      <div class="container mt-5 bg-white rounded-top shadow">
        <h4 class="pt-4 pb-1">Información Médica</h4>
        <div class="row pb-4">
          <div class="col">
            <label for="enfermedades" class="form-label">Enfermedades / Padecimientos Actuales</label>
            <textarea name="enfermedades" class="form-control" rows="5" maxlength="256"
              [(ngModel)]="servicio.enfermedades" maxlength="65535"></textarea>
          </div>
          <div class="col">
            <label for="procedimientos" class="form-label">Procedimientos Requeridos</label>
            <textarea name="procedimientos" class="form-control" rows="5" maxlength="256"
              [(ngModel)]="servicio.procedimientos" maxlength="65535"></textarea>
          </div>
        </div>
  
        <div class="row pb-4">
          <div class="col">
            <label for="medicamentos" class="form-label">Medicamentos a Administrar</label>
            <textarea name="medicamentos" class="form-control" rows="5" maxlength="256"
              [(ngModel)]="servicio.medicamentos" maxlength="65535"></textarea>
          </div>
          <div class="col">
            <label for="notas" class="form-label">Notas y Observaciones</label>
            <textarea name="notas" class="form-control" rows="5" maxlength="256"
              [(ngModel)]="servicio.notas" maxlength="65535"></textarea>
          </div>
        </div>
  
        <div class="row pb-4">
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">¿Tiene COVID?</label>
              <div class="form-control">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="tieneCovid" id="tieneCovidS" value="1" ngModel
                    #tieneCovid="ngModel" [(ngModel)]="servicio.tieneCovid">
                  <label class="form-check-label" for="tieneCovidS">Sí</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="tieneCovid" id="tieneCovidN" value="0" ngModel
                    #tieneCovid="ngModel" [(ngModel)]="servicio.tieneCovid">
                  <label class="form-check-label" for="tieneCovidN">No</label>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-md-2">
            <div class="form-group">
              <label class="control-label">¿Tiene Alzheimer?</label>
              <div class="form-control">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="tieneAlzheimer" id="tieneAlzheimerS" value="1"
                    ngModel #tieneAlzheimer="ngModel" [(ngModel)]="servicio.tieneAlzheimer">
                  <label class="form-check-label" for="tieneAlzheimerS">Sí</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="tieneAlzheimer" id="tieneAlzheimerN" value="0"
                    ngModel #tieneAlzheimer="ngModel" [(ngModel)]="servicio.tieneAlzheimer">
                  <label class="form-check-label" for="tieneAlzheimerN">No</label>
                </div>
              </div>
            </div>
          </div>
  
          <div class="col-md-3">
            <div class="form-group">
              <label class="control-label">¿Puede moverse por sí solo?</label>
              <div class="form-control">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="movimiento" id="movimientoS" value="1" ngModel
                    #movimiento="ngModel" [(ngModel)]="servicio.movimiento">
                  <label class="form-check-label" for="movimientoS">Sí</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="movimiento" id="movimientoN" value="0" ngModel
                    #movimiento="ngModel" [(ngModel)]="servicio.movimiento">
                  <label class="form-check-label" for="movimientoN">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row pb-4">
          <div class="col-md-12"><span class="font-italic">Los campos con </span><span
              class="text-danger font-italic">*</span><span class="font-italic"> son obligatorios</span></div>
        </div>
        <div class="row text-center pb-4">
          <div class="col">
            <button type="button" class="btn btn-primary" id="grabar" (click)="onGrabarServicio(myForm)" onclick="document.getElementById('grabar').disabled = true;document.getElementById('grabar').style.opacity='0.5';">Grabar Servicio</button>
          </div>
        </div>
      </div>
    </mat-tab> 
  </mat-tab-group>
  
 <div class="container bg-white rounded-bottom shadow">
    <div class="row text-center pb-4">
      <div class="col">
        <div class="btn-toolbar" role="toolbar" style="justify-content: center; display: flex;">
          <div class="btn-group mr-2">
            <button type="button" class="btn btn-primary" [formControl]="selected" (click)="pagAtras(1)">&lt;</button>
          </div>
          <div class="btn-group mr-2">
            <button type="button" class="btn btn-primary" [formControl]="selected" (click)="pagDelante(1)">&gt;</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>