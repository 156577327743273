<form style="background: #00D8D6; background: linear-gradient(to right, #00D8D6, #6EC1E4);" #myForm="ngForm">
    <ngb-toast-container></ngb-toast-container>
    <mat-tab-group mat-align-tabs="center" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
        <mat-tab label="Cotizador">
            <div class="container mt-5 pt-4 pb-4 bg-white rounded-top shadow">
                <h3 class="text-center">Cotizador de Servicios</h3>
                <hr class="py-1" />
                <div class="form-group row mt-4 mb-4">
                    <label for="horas" class="col-md-3 col-form-label">No. DE HORAS REQUERIDAS</label>                  
                    <div class="col-md-2">
                        <select class="form-control valGlobal"id="horas" name="horas" ngModel #horas="ngModel" 
                        [(ngModel)]="cotizadorInternoServicio.horas">
                          <option  value="4" >4</option>
                          <option value="8">8</option>
                          <option value="10">10</option>
                          <option value="12">12</option>
                          <option value="24">24</option>
                        </select>
                    </div>
                    <div class="col-md-1"><fa-icon [icon]="faQuestionCircle" style="color: gray;" matTooltip="Cantidad de días consecutivos en la semana" [matTooltipPosition]="position.value"></fa-icon></div>

                    <!-- <div class="col-md-2"><fa-icon [icon]="faQuestionCircle"></fa-icon></div>  -->
                </div>
                <div class="form-group row mb-4">
                    <label for="dias" class="col-md-3 col-form-label">No. DE DIAS CONTRATADO</label>                 
                    <div class="col-md-2">
                        <select class="form-control valGlobal" id="domingo" id="dias" name="dias" ngModel #dias="ngModel" 
                        [(ngModel)]="cotizadorInternoServicio.dias">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                        </select>
                    </div>
                    <div class="col-md-1"><fa-icon [icon]="faQuestionCircle" style="color: gray;" matTooltip="Cantidad de horas diarias requeridas en el servicio" [matTooltipPosition]="position.value"></fa-icon></div>

                </div>
                <div class="form-group row mb-4">
                    <label for="hombre" class="col-md-3 col-form-label">SE REQUIERE ENFERMERO MASCULINO</label>
                    <div class="col-md-2">
                        <!-- <input type="number" class="form-control valGlobal" id="hombre" name="hombre" ngModel #hombre="ngModel" [(ngModel)]="cotizadorInternoServicio.hombre">                     -->
                        <select class="form-control valGlobal" id="hombre" name="hombre" ngModel #hombre="ngModel"
                          [(ngModel)]="cotizadorInternoServicio.hombre">
        
                          <option value="1">Sí</option>
                          <option value="0">No</option>
                        </select>
                    </div>
                    <!-- <div class="col-md-1">
                        0 = NO
                        <br>
                        1 = SI
                    </div> -->
                    <div class="col-md-1"><fa-icon [icon]="faQuestionCircle" style="color: gray;" matTooltip="En el combo de la izquierda seleccionar 'Sí' si se requiere de un enfermero masculino" [matTooltipPosition]="position.value"></fa-icon></div>
                </div>
                <div class="form-group row mb-4">
                    <label for="domingo" class="col-md-3 col-form-label">¿TRABAJA EN DOMINGO?</label>
                    <div class="col-md-2">
                        <!-- <input type="number" class="form-control valGlobal" id="domingo" name="domingo" ngModel #domingo="ngModel" [(ngModel)]="cotizadorInternoServicio.domingo">                     -->
                        <select class="form-control valGlobal" id="domingo" name="domingo" ngModel #domingo="ngModel"
                          [(ngModel)]="cotizadorInternoServicio.domingo">
        
                          <option value="1">Sí</option>
                          <option value="0">No</option>
                        </select>
                    </div>
                    <!-- <div class="col-md-1">
                        0 = NO
                        <br>
                        1 = SI
                    </div> -->
                    <div class="col-md-1"><fa-icon [icon]="faQuestionCircle" style="color: gray;" matTooltip="En el combo de la izquierda seleccionar 'Sí' si se requiere el servicio en domingo" [matTooltipPosition]="position.value"></fa-icon></div>
                </div>
                <div class="form-group row mb-4">
                    <label for="asueto" class="col-md-3 col-form-label">¿TRABAJA EN ASUETO?</label>
                    <div class="col-md-2">
                        <!-- <input type="number" class="form-control valGlobal" id="asueto" name="asueto" ngModel #asueto="ngModel" [(ngModel)]="cotizadorInternoServicio.asueto">                     -->
                        <select class="form-control valGlobal" id="asueto" name="asueto" ngModel #asueto="ngModel"
                          [(ngModel)]="cotizadorInternoServicio.asueto">
        
                          <option value="1">Sí</option>
                          <option value="0">No</option>
                        </select>
                    </div>
                    <!-- <div class="col-md-1">
                        0 = NO
                        <br>
                        1 = SI
                    </div> -->
                    <div class="col-md-1"><fa-icon [icon]="faQuestionCircle" style="color: gray;" matTooltip="En el combo de la izquierda seleccionar 'Sí' si se requiere el servicio en asueto" [matTooltipPosition]="position.value"></fa-icon></div>
                </div>
                <div class="form-group row mb-4">
                    <label for="hospital" class="col-md-3 col-form-label">¿SERVICIO EN HOSPITAL?</label>
                    <div class="col-md-2">
                        <!-- <input type="number" class="form-control valGlobal" id="hospital" name="hospital" ngModel #hospital="ngModel" [(ngModel)]="cotizadorInternoServicio.hospital">                     -->
                        <select class="form-control valGlobal" id="hospital" name="hospital" ngModel #hospital="ngModel"
                          [(ngModel)]="cotizadorInternoServicio.hospital">
        
                          <option value="1">Sí</option>
                          <option value="0">No</option>
                        </select>
                    </div>
                    <!-- <div class="col-md-1">
                        0 = NO
                        <br>
                        1 = SI
                    </div> -->
                    <div class="col-md-1"><fa-icon [icon]="faQuestionCircle" style="color: gray;" matTooltip="En el combo de la izquierda seleccionar 'Sí' si se requiere el servicio en hospital" [matTooltipPosition]="position.value"></fa-icon></div>
                </div>
                <div class="form-group row mb-4">
                    <label for="servicioFuera" class="col-md-3 col-form-label">¿SERVICIO EN SAN PEDRO / CARR. NACIONAL?</label>
                    <div class="col-md-2">
                        <!-- <input type="number" class="form-control valGlobal" id="servicioFuera" name="servicioFuera" ngModel #servicioFuera="ngModel" [(ngModel)]="cotizadorInternoServicio.servicioFuera">                     -->
                        <select class="form-control valGlobal" id="servicioFuera" name="servicioFuera" ngModel #servicioFuera="ngModel"
                          [(ngModel)]="cotizadorInternoServicio.servicioFuera">
        
                          <option value="1">Sí</option>
                          <option value="0">No</option>
                        </select>
                    </div>
                    <!-- <div class="col-md-1">
                        0 = NO
                        <br>
                        1 = SI
                    </div> -->
                    <div class="col-md-1"><fa-icon [icon]="faQuestionCircle" style="color: gray;" matTooltip="En el combo de la izquierda seleccionar 'Sí' si se requiere el servicio fuera de la cobertura normal" [matTooltipPosition]="position.value"></fa-icon></div>
                    <div class="col-md-1">
                        <fa-icon [icon]="faArrowRight"></fa-icon>
                    </div>
                    <label for="noTaxis" class="col-md-2 col-form-label">No. DE TAXIS</label>
                    <div class="col-md-2">
                        <input type="number" class="form-control valGlobal" id="noTaxis" name="noTaxis" ngModel #noTaxis="ngModel" [(ngModel)]="cotizadorInternoServicio.noTaxis">                    
                    </div>
                    <div class="col-md-1"><fa-icon [icon]="faQuestionCircle" style="color: gray;" matTooltip="Número de taxis requeridos para transportación fuera de la zona de cobertura" [matTooltipPosition]="position.value"></fa-icon></div>
                </div>
                <hr class="py-1" />
                <mat-accordion *ngIf="cotizarClick">
                    <!-- #enddocregion basic-panel -->
                      <mat-expansion-panel (opened)="panelOpenState = true"
                                           (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title style="font-size: 111%;">
                            PRECIOS DE LISTA
                          </mat-panel-title>
                          <mat-panel-description style="font-size: 111%;">
                            {{cotizadorInternoServicio.dias}} Dias
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th style="font-size: 111%;">PRECIO TOTAL</th>
                                    <th style="font-size: 111%;">PRECIO POR DIA</th>
                                </tr>
                                <tr>
                                    <th scope="row" style="font-size: 111%;">CUIDADOR</th>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioListaCuidador | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioListaCuidadorPorDia | currency}}</td>
                                </tr>
                                <tr>
                                    <th scope="row" style="font-size: 111%;">ENFERMERA</th>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioListaEnfermera | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioListaEnfermeraPorDia | currency}}</td>
                                </tr>
                                <tr>
                                    <th scope="row" style="font-size: 111%;">ENFERMERA COVID</th>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioListaEnfermeraCovid | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioListaEnfermeraCovidPorDia | currency}}</td>
                                </tr>  
                            </tbody>
                          </table>
                      </mat-expansion-panel>
    
                      <mat-expansion-panel (opened)="panelOpenState = true"
                                           (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title style="font-size: 111%;">
                            PRECIOS EN PAQUETE
                          </mat-panel-title>
                          <mat-panel-description style="font-size: 111%;">
                            {{cotizadorInternoServicio.dias}} Dias
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th style="font-size: 111%;">PRECIO TOTAL</th>
                                    <th style="font-size: 111%;">PRECIO POR DIA</th>
                                </tr>
                                <tr>
                                    <th scope="row" style="font-size: 111%;">CUIDADOR</th>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioPaqueteCuidador | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioPaqueteCuidadorPorDia | currency}}</td>
                                </tr>
                                <tr>
                                    <th scope="row" style="font-size: 111%;">ENFERMERA</th>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioPaqueteEnfermera | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioPaqueteEnfermeraPorDia | currency}}</td>
                                </tr>
                                <tr>
                                    <th scope="row" style="font-size: 111%;">ENFERMERA COVID</th>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioPaqueteEnfermeraCovid | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.precioPaqueteEnfermeraCovidPorDia | currency}}</td>
                                </tr>  
                            </tbody>
                          </table>
                          <div class="row" style="color: black;">** LOS PAQUETES SOLO SE OFRECEN PARA SERVICIOS DE 24 HORAS, DIAS CONSECUTIVOS Y QUE EL PERSONAL SE QUEDE EN LA CASA DEL PACIENTE U HOSPITAL.</div>
                          <div class="row" style="color: black;">** SE DEBE ALIMENTAR AL CUIDADOR / ENFERMERA</div>
                      </mat-expansion-panel>
    
                      <mat-expansion-panel (opened)="panelOpenState = true"
                                           (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                          <mat-panel-title style="font-size: 111%;">
                            PRECIOS DE POLIZAS
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th style="font-size: 111%;">1 MES</th>
                                    <th style="font-size: 111%;">2 MESES</th>
                                    <th style="font-size: 111%;">3 MESES</th>
                                    <th style="font-size: 111%;">4 MESES</th>
                                </tr>
                                <tr>
                                    <th scope="row" style="font-size: 111%;">CUIDADORA</th>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaCuidadora1 | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaCuidadora2 | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaCuidadora3 | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaCuidadora4 | currency}}</td>
                                </tr>
                                <tr>
                                    <th scope="row" style="font-size: 111%;">ENFERMERA</th>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaEnfermera1 | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaEnfermera2 | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaEnfermera3 | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaEnfermera4 | currency}}</td>
                                </tr>
                                <tr>
                                    <th scope="row" style="font-size: 111%;">ENFERMERA COVID</th>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaEnfermeraCovid1 | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaEnfermeraCovid2 | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaEnfermeraCovid3 | currency}}</td>
                                    <td style="font-size: 111%;">{{cotizadorInternoServicio.polizaEnfermeraCovid4 | currency}}</td>
                                </tr>  
     
                            </tbody>
                          </table>
                          <div class="row" style="color: black;">** No. DE REEMPLAZOS: 1, 2 y 3</div>
                          <div class="row" style="color: black;">** EN CASO DE REQUERIR QUE SEA VARON ES MAS EL 20% (POR LA ESCASES DE PERSONAL MASCULINO)</div>
                      </mat-expansion-panel>
                      <div class="col-md-1 mt-4"><fa-icon [icon]="faQuestionCircle" style="color: gray;" matTooltip="Precios de Polizas:
                        &#8226; 1 ó 2 meses: 1 reemplazo
                        &#8226; 3 meses: 2 reemplazos
                        &#8226; 4 meses: 3 reemplazos" [matTooltipPosition]="position.value"></fa-icon></div>
                    </mat-accordion>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div class="container bg-white rounded-bottom shadow">
      <div class="row text-center pb-4">
        <div class="col">
          <button type="button" class="btn btn-primary" (click)="onCotizar()"> Cotizar </button>
        </div>
      </div>
    </div>
</form>