<div class="container mt-5 pt-4 bg-white rounded-top shadow">
  <button type="button" class="btn btn-success mb-4 float-left" (click)="agregarServicio()">
    <h3>
      <fa-icon [icon]="faBriefcaseMedical"></fa-icon>
    </h3> Agregar Servicio
  </button>
  <button type="button" class="btn btn-warning my-3 float-right" (click)="crearReporte()">
    Reporte
  </button>
  <button type="button" class="btn btn-warning my-3 float-right" (click)="crearReporteColaborador()">
    Reporte Colaborador
  </button>
  <table class="table">
    <tbody>
      <tr>
        <th scope="row">Fecha</th>
        <td>
          <input type="date" class="form-control" id="fechanacimiento" name="fechanacimientoDesde" value="yyyy-MM-dd"
            [(ngModel)]="servicioFiltro.fecha1">
        </td>
        <td>
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </td>
        <td>
          <input type="date" class="form-control" id="fechanacimiento" name="fechanacimientoHasta" value="yyyy-MM-dd"
            [(ngModel)]="servicioFiltro.fecha2">
        </td>
        <th scope="row">Responsable</th>
        <td>
          <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="responsablesSettings" [data]="responsables"
            [(ngModel)]="responsablesSelected">
          </ng-multiselect-dropdown>
        </td>
        <th scope="row">Estatus</th>
        <td>
          <div class="input-group">
            <!-- <select class="form-control" name="estatusFiltro" [(ngModel)]="servicioFiltro.estatus">
              <option value="" selected disabled hidden>Seleccionar</option>
              <option value="Abierta">Abierta</option>
              <option value="Asignada">Asignada</option>
            </select> -->
            <select class="form-control" name="estatusOperativo" #estatusOperativo="ngModel" [(ngModel)]="servicioFiltro.estatusOperativo" ngModel  
            (change)="onEstatusOperacion($event.target.value)">
              <option *ngFor="let estatusOperativo of estatusOperaciones" [value]="estatusOperativo.idTipoEstatusOperacion">
                {{estatusOperativo.Nombre}}
              </option>
            </select>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">Estatus Pago</th>
        <td>
          <div class="input-group">
            <select class="form-control" name="estatusPago" #estatusPago="ngModel" [(ngModel)]="servicioFiltro.estatusPago" ngModel  
            (change)="onEstatusPago($event.target.value)">
              <option *ngFor="let estatusPago of estatusPagos" [value]="estatusPago.idTipoEstatusPago">
                {{estatusPago.Nombre}}
              </option>
            </select>
          </div>
        </td>
        <th scope="row">Paciente</th>
        <td>
          <ng-multiselect-dropdown [placeholder]="'Seleccionar'" [settings]="pacientesSettings" [data]="pacientes"
            [(ngModel)]="pacientesSelected">
          </ng-multiselect-dropdown>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row text-center pb-4">
    <div class="col">
      <button type="button" class="btn btn-primary" (click)="getServicios()">Buscar</button> &nbsp;&nbsp;
      <button type="button" class="btn btn-danger" (click)="resetFields()">Limpiar Filtro</button> &nbsp;&nbsp;
    </div>
  </div>
</div>

<div class="container bg-white rounded-bottom shadow pb-4">
  <div class="mat-elevation-z8">
    <table mat-table matTableExporter [dataSource]="servicioSource" class="mat-elevation-z8"
      #exporter="matTableExporter">
      <ng-container matColumnDef="idServicio">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.idServicio}} </td>
      </ng-container>
      <ng-container matColumnDef="fechaCreacion">
        <th mat-header-cell *matHeaderCellDef  > Fecha Alta </th>
        <td mat-cell *matCellDef="let element"> {{element.formatoFecha}} </td>
      </ng-container>
      <ng-container matColumnDef="semanaAlta">
        <th mat-header-cell *matHeaderCellDef> Semana del Servicio </th>
        <td mat-cell *matCellDef="let element"> {{element.semanaAlta}} </td>
      </ng-container>
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Paciente </th>
        <td mat-cell *matCellDef="let element"> {{element.nombrecompleto}} </td>
      </ng-container>
      <ng-container matColumnDef="responsable">
        <th mat-header-cell *matHeaderCellDef> Responsable</th>
        <td mat-cell *matCellDef="let element"> {{element.nombreResp}} </td>
      </ng-container>
      <ng-container matColumnDef="estatus">
        <th mat-header-cell *matHeaderCellDef> Estatus Operación</th>
        <td mat-cell *matCellDef="let element"> {{element.estatusOperativoNombre}} </td>
      </ng-container>
      <ng-container matColumnDef="estatusPago">
        <th mat-header-cell *matHeaderCellDef> Estatus Pago</th>
        <td mat-cell *matCellDef="let element"> {{element.estatusPagoNombre}} </td>
      </ng-container>
      <ng-container matColumnDef="cantidadPorPagar">
        <th mat-header-cell *matHeaderCellDef> Pago Pend.</th>
        <td mat-cell *matCellDef="let element"> {{element.cantidadPorPagar}} </td>
      </ng-container>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef> Acciones </th>
        <td mat-cell *matCellDef="let element">
          <button class="btn btn-primary mt-1 mb-1" (click)="modificacionServicio(element.idServicio)">
            <mat-icon>edit</mat-icon>
          </button>
          <button class="btn btn-primary mb-1" (click)="openDialog(element.idServicio)">
            <mat-icon>assignment</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="servicioColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: servicioColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[1, 5, 25, 100]"
      (page)="pageEvent = getServicios($event)" aria-label="Selecciona los elementos a mostrar">
    </mat-paginator>
  </div>
</div>