export class Contacto {
    public idContacto: number;
    public nombre: string;
    public parentesco: number;
    public telefono: number;
    public tipoTelefono: number;
    public correoElectronico: string;
    
    constructor() {
    }
}