<div class="container mt-5 pt-4 bg-white rounded-top shadow">
    <h3 class="text-center">Reporte de Asignacion de Colaborador</h3>
    <table class="table">
      <tbody>
        <tr>
          <th scope="row">Fecha</th>
          <td>
            <input type="date" class="form-control" id="fechaDesde" name="fechaDesde" value="yyyy-MM-dd" [(ngModel)]="servicioFiltro.fecha1">
          </td>
          <td>
            <fa-icon [icon]="faArrowRight"></fa-icon>
          </td>
          <td>
            <input type="date" class="form-control" id="fechaHasta" name="fechaHasta" value="yyyy-MM-dd" [(ngModel)]="servicioFiltro.fecha2">
          </td>
        </tr>
      </tbody>
    </table>
  
    <div class="row text-center pb-4">
      <div class="col">
        <button type="button" class="btn btn-primary" (click)="getServicios()">Buscar</button> &nbsp;&nbsp;
        <button type="button" class="btn btn-danger" (click)="resetFields()">Limpiar Filtro</button> &nbsp;&nbsp;
      </div>
    </div>
  </div>
  
  <div class="container bg-white rounded-bottom shadow pb-4">
    <div class="mat-elevation-z8">
      <table mat-table matTableExporter [dataSource]="servicioSource" class="mat-elevation-z8"
        #exporter="matTableExporter">
        <ng-container matColumnDef="idServicio">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.idServicio}} </td>
        </ng-container>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Paciente </th>
            <td mat-cell *matCellDef="let element"> {{element.nombrecompleto}} </td>
          </ng-container>
        <ng-container matColumnDef="fechaCreacion">
          <th mat-header-cell *matHeaderCellDef> Fecha Alta </th>
          <td mat-cell *matCellDef="let element"> {{element.formatoFecha}} </td>
        </ng-container>
        <ng-container matColumnDef="estatus">
          <th mat-header-cell *matHeaderCellDef> Estatus</th>
          <td mat-cell *matCellDef="let element"> {{element.estatus}} </td>
        </ng-container>
        <ng-container matColumnDef="colaboradores">
          <th mat-header-cell *matHeaderCellDef> Colaboradores</th>
          <td mat-cell *matCellDef="let element"> {{element.nombrecompletocolab}} </td>
          
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="servicioColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: servicioColumns;"></tr>
      </table>
      <!-- <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[1, 5, 25, 100]"
        (page)="pageEvent = getServicios($event)" aria-label="Selecciona los elementos a mostrar">
      </mat-paginator> -->
    </div>
    
    <div class="row text-center mt-4">
        <div class="col">
          <button class="btn btn-success" (click)="exporter.exportTable('xlsx', {fileName:'reporteCobranza'})"><fa-icon [icon]="faFileExcel"></fa-icon> Exportar como Excel</button>
        </div>
      </div>
  </div>

